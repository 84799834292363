<template>
  <div class="w-full">
    <input ref="deviceCapture" type="file" :accept="accept.toString()" capture="user" class="hidden" @change="(e) => recordedFromDevice(e)">
    <div class="flex gap-2 mx-auto max-w-fit">
      <div v-if="!((/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)) && deviceCapture?.capture !== undefined" class="btn-secondary inline-block" @click="deviceCapture.click()">
        Open Camera
      </div>
      <template v-else-if="((/iPad|iPhone|iPod/.test(navigator.userAgent)) || (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1))">
        <template v-if="accept.includes('video/*')">
          <button
            class="btn-secondary"
            @click="openCamera"
          >
            Open Camera
          </button>
        </template>
      </template>
      <template v-else>
        <template v-if="accept.includes('image/*') && accept.includes('video/*')">
          <Menu as="div" class="inline-block text-left relative">
            <div>
              <MenuButton
                class="btn-secondary flex"
              >
                Open Camera
                <ChevronDownIcon
                  class="-mr-1 ml-2 h-5 w-5 text-violet-200 hover:text-violet-100"
                  aria-hidden="true"
                />
              </MenuButton>
            </div>
            <transition
              enter-active-class="transition duration-100 ease-out"
              enter-from-class="transform scale-95 opacity-0"
              enter-to-class="transform scale-100 opacity-100"
              leave-active-class="transition duration-75 ease-in"
              leave-from-class="transform scale-100 opacity-100"
              leave-to-class="transform scale-95 opacity-0"
            >
              <MenuItems
                class="absolute z-10 right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none"
              >
                <div class="px-1 py-1">
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-primary-500 text-white' : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      ]"
                      @click="openCamera"
                    >
                      Record Video
                    </button>
                  </MenuItem>
                  <MenuItem v-slot="{ active }">
                    <button
                      :class="[
                        active ? 'bg-primary-500 text-white' : 'text-gray-900',
                        'group flex w-full items-center rounded-md px-2 py-2 text-sm',
                      ]"
                      @click="photoMode = true"
                    >
                      Take Photo
                    </button>
                  </MenuItem>
                </div>
              </MenuItems>
            </transition>
          </Menu>
        </template>
        <template v-else-if="accept.includes('image/*') && !accept.includes('video/*')">
          <button
            class="btn-secondary"
            @click="photoMode = true"
          >
            Open Camera
          </button>
        </template>
        <template v-else-if="!accept.includes('image/*') && accept.includes('video/*')">
          <button
            class="btn-secondary"
            @click="openCamera"
          >
            Open Camera
          </button>
        </template>
        <RecordingModal
          :camera-open="photoMode"
          :open="photoMode"
          @close="photoMode=false"
        >
          <div class="absolute inset-0 w-full z-[999999] h-full bg-black">
            <Camera
              @success="(blob) => photoFromDevice(blob)"
            />
          </div>
        </RecordingModal>
      </template>
      <template v-if="$env.VITE_ENABLE_SCREEN_RECORD">
        <div class="btn-secondary inline-block cursor-pointer" @click="startRecordingScreen">
          Record Screen
        </div>
      </template>
    </div>
    <div v-if="!!recorderError" class="text-xs text-red-700 mt-2">
      {{ recorderError }}
    </div>
    <div v-if="errors.length" class="form-error">
      {{ errors[0] }}
    </div>
    <RecordingModal
      :camera-open
      :recording
      :open="isOpen"
      title="Record"
      @close="closeModal()"
    >
      <div :class="cameraOpen ? 'absolute inset-0 w-full z-[999999]' : 'relative'" class="h-full">
        <video
          v-show="cameraOpen"
          ref="video"
          :autoplay="!!cameraOpen && (!video?.src || !!recording )"
          playsinline
          :controls="!!video?.src && !recording"
          :class="['bg-black w-full h-full', {'scale-x-[-1]': isMirrored && !video?.src}, { 'pb-11' : !!video?.src && !recording }]"
        />
        <div v-if="!!video?.src && cameraOpen && !recording" class="absolute bottom-0 left-0 w-full flex flex-row">
          <div class="text-white hover:text-primary-200 cursor-pointer whitespace-nowrap border-t border-r border-white/20 w-1/2 px-6 py-3 text-sm text-center" @click="retryRecording">
            Retry
          </div>
          <div class="text-white hover:text-primary-200 cursor-pointer whitespace-nowrap border-t border-white/20 w-1/2 px-6 py-3 text-sm text-center" @click="submitFile">
            Submit
          </div>
        </div>
        <div v-if="!video?.src && !recording && cameraOpen" class="absolute bottom-6 w-full">
          <div class="flex justify-between gap-12 mx-auto max-w-fit mb-2">
            <select v-if="availableCameras.length > 1" v-model="selectedCameraId" class="px-2 py-1 border rounded" @change="openCamera">
              <option v-for="camera in availableCameras" :key="camera.deviceId" :value="camera.deviceId" :selected="selectedCameraId === camera.deviceId">
                {{ camera.label || 'Camera ' + camera.deviceId }}
              </option>
            </select>
            <div @click="isMirrored = !isMirrored">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="size-12 text-white drop-shadow-lg" fill="currentColor">
                <path d="M256 138.667c-5.867 0-10.667 4.8-10.667 10.667v352c0 5.867 4.8 10.667 10.667 10.667s10.667-4.8 10.667-10.667v-352c0-5.867-4.8-10.667-10.667-10.667z" />
                <path d="M106.667 170.667c5.867 0 10.667-4.8 10.667-10.667C117.44 83.413 179.413 21.44 256 21.333c68.373 0 125.12 49.707 136.427 114.88l-52.8-37.76c-4.8-3.307-11.52-2.133-14.827 2.773-3.307 4.693-2.24 11.2 2.453 14.613l68.907 49.387c1.813 3.413 5.333 5.547 9.173 5.653.32 0 .533-.107.853-.213.427.107.747.107 1.173.213 1.6 0 3.093-.427 4.587-1.067.107 0 .213-.107.32-.107 1.387-.747 2.56-1.813 3.52-2.987.107-.107.213-.107.32-.213.107-.107 0-.107.107-.213.107-.107.213-.213.213-.32l40.64-64c3.093-5.013 1.493-11.627-3.52-14.613-4.907-2.987-11.307-1.6-14.4 3.2l-25.813 40.64C399.467 56.533 334.293 0 256 0 167.787 0 96 71.787 96 160c0 5.867 4.8 10.667 10.667 10.667zM199.04 312 28.373 162.667c-4.48-3.84-11.2-3.413-15.04.96-1.707 1.92-2.667 4.48-2.667 7.04v298.667c0 5.867 4.8 10.667 10.667 10.667 2.56 0 5.12-.96 7.04-2.667L199.04 328c4.48-3.84 4.907-10.667.96-15.04-.32-.32-.64-.747-.96-.96zM32 445.867V194.133L175.787 320 32 445.867zM495.04 160.96c-3.84-1.707-8.32-1.067-11.413 1.707L312.96 312c-4.48 3.84-4.907 10.667-.96 15.04.32.32.64.747.96.96l170.667 149.333c1.92 1.707 4.48 2.667 7.04 2.667 5.867 0 10.667-4.8 10.667-10.667V170.667c-.001-4.16-2.454-8-6.294-9.707zM480 445.867 336.213 320 480 194.133v251.734z" />
              </svg>
            </div>
          </div>
          <div class="bg-gray-200 hover:bg-gray-100 rounded-full p-2 cursor-pointer m-auto w-fit" @click="startRecordingCamera">
            <div class="size-9 rounded-full bg-red-700 m-1" />
          </div>
        </div>
        <div v-if="recording" class="absolute bottom-6 w-full">
          <div class="bg-gray-200 hover:bg-gray-100 rounded-full p-3 cursor-pointer m-auto w-fit" @click="stopRecording">
            <div class="size-8 bg-black rounded m-1" />
          </div>
        </div>
        <div v-if="recording" class="absolute top-6 w-full">
          <div class="bg-red-400 text-white rounded-full p-2 m-auto w-fit">
            <DurationString :duration="counter" class="mx-auto inline-block " />
          </div>
        </div>
      </div>
    </RecordingModal>
  </div>
</template>

<script setup>
import RecordRTC from 'recordrtc'
import fixWebmDuration from "fix-webm-duration";
import { onUnmounted, ref } from 'vue';
import DurationString from '../../Shared/DurationString.vue';
import RecordingModal from '../../Shared/RecordingModal.vue';
import { useInterval } from '@vueuse/core'
import { Menu, MenuButton, MenuItems, MenuItem } from '@headlessui/vue'
import { ChevronDownIcon } from '@heroicons/vue/20/solid'
import Camera from './Camera.vue';

const video = ref()
const recorder = ref()
const startTime = ref()
const cameraOpen = ref(false)
const recording = ref(false)
const source = ref('camera')
const deviceCapture = ref()
const recorderError = ref('')
const isOpen = ref(false)
const tempFile = ref()
const isMirrored = ref(true)
const availableCameras = ref([])
const selectedCameraId = ref('')

defineProps({
  modelValue: File,
  errors: {
    type: Array,
    default: () => [],
  },
  accept: {
    type: Array,
    required: false,
    default: () => { return ['video/*', 'image/*'] }
  }
})

const { counter, reset} = useInterval(1000, { controls: true })

const emit = defineEmits(['update:modelValue', 'recording'])
const photoMode = ref(false)

const startRecordingScreen = () => {
  isOpen.value = true
  source.value='screen'
  captureScreen(function(screen) {
    video.value.srcObject = screen;

    recorder.value = RecordRTC(screen, {
      type: 'video'
    });

    recording.value=true
    emit('recording', true)
    reset()
    startTime.value = Date.now()
    recorder.value.startRecording();
    cameraOpen.value = true;
    // release screen on stopRecording
    recorder.value.screen = screen;
  });
}

const openCamera = () => {
  isOpen.value = true
  source.value='camera'
  captureCamera(function(camera) {
    navigator.mediaDevices
      .enumerateDevices()
      .then(deviceInfos => {
        deviceInfos.forEach(deviceInfo => {
          if (deviceInfo.deviceId && deviceInfo.kind === 'videoinput' && !availableCameras.value.find(el => el.deviceId === deviceInfo.deviceId)) {
            availableCameras.value.push(deviceInfo)
          }
        });
        if (availableCameras.value.length > 1) {
          selectedCameraId.value = availableCameras.value[0].deviceId;
        }
      });
    cameraOpen.value=true;
    video.value.muted = true;
    video.value.volume = 0;
    video.value.srcObject = camera;

    if (MediaRecorder.isTypeSupported('video/mp4')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/mp4',
        timeSlice: 1000
      });
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm;codecs=h264',
        timeSlice: 1000
      });
    } else {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm',
        timeSlice: 1000
      });
    }
    // release camera on stopRecording
    recorder.value.camera = camera;
  });
};

const startRecordingCamera = () => {
  recording.value = true
  emit('recording', true)
  reset()
  startTime.value = Date.now()
  recorder.value.startRecording();
}

const stopRecording = () => {
  recorder.value?.stopRecording(stopRecordingCallback);
}

const invokeGetDisplayMedia = (success, error) => {
  let displaymediastreamconstraints = {
    video: {
      displaySurface: 'monitor', // monitor, window, application, browser
      logicalSurface: true,
      cursor: 'always' // never, always, motion
    }
  };

  // above constraints are NOT supported YET
  // that's why overriding them
  displaymediastreamconstraints = {
    video: true
  };

  if(navigator.mediaDevices.getDisplayMedia) {
    navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
  }
  else {
    navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
  }
}

const captureScreen = (callback) => {
  invokeGetDisplayMedia(function(screen) {
    addStreamStopListener(screen, function() {
      stopRecording();
    });
    callback(screen);
  }, function(error) {
    console.error(error);
    alert('Unable to capture your screen. Please check console logs.\n' + error);
  });
}

const captureCamera = (callback) => {
  navigator.mediaDevices.getUserMedia({ audio: true, video: {
      deviceId: selectedCameraId.value ? { exact: selectedCameraId.value } : {},
    }}).then(function(camera) {
    callback(camera);
  }).catch(function(error) {
    cameraOpen.value = false
    stopRecording();
    if (deviceCapture.value?.capture !== undefined) {
      deviceCapture.value.click()
    } else {
      recorderError.value = 'Unable to find a suitable camera!';
      console.error(error);
    }
  });
}

const stopRecordingCallback = () => {
  video.value.src = video.value.srcObject = null;
  let duration = Date.now() - startTime.value

  if (MediaRecorder.isTypeSupported('video/webm;codecs=h264') || MediaRecorder.isTypeSupported('video/mp4')) {
    let blob = recorder.value.getBlob();
    video.value.src = URL.createObjectURL(blob);
    tempFile.value = new File([blob], `Recorded-${new Date().toJSON()}.mp4`, {
      type: 'video/mp4'
    });
  } else {
    fixWebmDuration(
      recorder.value.getBlob(),
      duration,
      (seekableBlob) => {
        video.value.src = URL.createObjectURL(seekableBlob);
        tempFile.value = new File([seekableBlob], `Recorded-${new Date().toJSON()}.webm`, {
          type: 'video/webm'
        });
      }
    );
  }

  recording.value=false
  emit('recording', false)
  if (source.value === 'screen') {
    recorder.value.screen.stop();
  } else if (source.value === 'camera') {
    video.value.muted = false;
    video.value.volume = 1;
    recorder.value.camera.stop();
  }
  recorder.value.destroy();
  recorder.value = null;
}

const addStreamStopListener = (stream, callback) => {
  stream.addEventListener('ended', function() {
    callback();
    callback = function() {};
  }, false);
  stream.addEventListener('inactive', function() {
    callback();
    callback = function() {};
  }, false);
  stream.getTracks().forEach(function(track) {
    track.addEventListener('ended', function() {
      callback();
      callback = function() {};
    }, false);
    track.addEventListener('inactive', function() {
      callback();
      callback = function() {};
    }, false);
  });
}

defineExpose({ stopRecording })

const recordedFromDevice = (e) => {
  emit('update:modelValue', e.target.files[0])
}

const photoFromDevice = (blob) => {
  photoMode.value = false
  let file = new File(
    [blob],
    `IMG-${new Date().toJSON()}.jpg`,
    {type:"image/jpeg", lastModified:new Date().getTime()}, 'utf-8'
  );
  emit('update:modelValue', file)
}

const retryRecording = () => {
  video.value.src = video.value.srcObject = null;
  source.value='camera'
  captureCamera(function(camera) {
    cameraOpen.value=true;
    video.value.muted = true;
    video.value.volume = 0;
    video.value.srcObject = camera;

    if (MediaRecorder.isTypeSupported('video/mp4')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/mp4',
        timeSlice: 1000
      });
    } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm;codecs=h264',
        timeSlice: 1000
      });
    } else {
      recorder.value = RecordRTC(camera, {
        recorderType:  RecordRTC.MediaStreamRecorder,
        type: 'video',
        mimeType: 'video/webm',
        timeSlice: 1000
      });
    }

    recording.value = true
    emit('recording', true)
    reset()
    startTime.value = Date.now()
    recorder.value.startRecording();
    // release camera on stopRecording
    recorder.value.camera = camera;
  });
}

const navigator = window.navigator

const submitFile = () => {
  cameraOpen.value = false
  isOpen.value = false
  emit('update:modelValue', tempFile.value);
}

const killCamera = () => {
  recording.value=false
  emit('recording', false)
  if (source.value === 'screen') {
    recorder.value?.screen?.stop();
  } else if (source.value === 'camera') {
    recorder.value?.camera?.stop();
  }
  recorder.value?.destroy();
  recorder.value = null;
}

const closeModal = () => {
  if(recording.value === true) {
    stopRecording()
    killCamera()
    isOpen.value = false
    if(confirm('Would you like to save your recording in progress?')) {
      emit('update:modelValue', tempFile.value);
    }
  } else {
    isOpen.value = false
  }
}

onUnmounted(() => {
  killCamera()
});
</script>
