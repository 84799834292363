<template>
  <form @submit.prevent="$emit('submit')">
    <div class="-mb-8 -mr-6 flex flex-wrap p-8">
      <TextInput
        v-model="form.user.first_name"
        :errors="form.errors.first_name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="First name"
      />
      <TextInput
        v-model="form.user.last_name"
        :errors="form.errors.last_name"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Last name"
      />
      <TextInput
        v-model="form.user.email"
        :errors="form.errors.email"
        class="w-full pb-8 pr-6 lg:w-1/2"
        label="Email"
      />
    </div>
    <slot />
  </form>
</template>

<script setup>
import { computed, defineProps, defineEmits } from 'vue';
import TextInput from '@/Shared/TextInput.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'submit']);

const form = computed({
  get: () => props.modelValue,
  set: (val) => emit('update:modelValue', val),
});
</script>
