<template>
  <Layout>
    <Head title="Support" />
    <h1 class="mb-8 text-3xl font-bold">
      Support
    </h1>
    <div class="w-full flex gap-6 flex-col-reverse lg:flex-row">
      <div class="w-full">
        <h2 class="mb-4 text-xl font-semibold">
          Frequently Asked Questions
        </h2>
        <div class="mx-auto w-full rounded bg-white p-2">
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I create a new playlist?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              From the <Link :href="$routes.playlists()" class="text-primary">
                Playlists page
              </Link>, click the "Create Playlist" button.  You will prompted to give the playlist a title,
              <template v-if="!!config.enablePlaylistDueDate">
                a due date,
              </template>
              <template v-if="!!config.enablePlaylistContributors">
                customize the invitation email message,
              </template>
              and optionally customize the background color.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I add videos to my playlist?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              If you have previously added videos to your media library, you may use the Add To Playlist button on the video card to select the target playlist.  If you would like to upload directly to a playlist, click the Add Media button on the listing on the Playlists index page, or on the Media tab for the playlist in question.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-if="!!config.enablePlaylistContributors" v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I invite contributors?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              From your Playlist, navigate to the Contributors tab.  You can invite one person at a time by providing a name and email, or you can bulk import a CSV file.
              You may customize the content of the invitation email from the Settings tab.
              <template v-if="!!config.enablePlaylistDueDate">
                If you selected a Playlist due date, a countdown timer will appear in the email.
              </template>
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-if="!!config.enablePlaylistContributors && !config.enableClients" v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How is a Collaborator different from a Contributor?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              Contributors are bound to a specific Playlist, and may upload or record a single video for that Playlist.  Collaborators can be granted customizable permissions for as many or as few playlists as you would like them to access, manage, and/or invite contributors in a collaborative effort with you.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I share my finalized tribute with the intended recipient?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              From the playlists index or the individual Playlist page, click the Copy URL button.  Once you have the publicly viewable URL copied to your clipboard, you may share the link via email or SMS.  Click the QR Code button to view and download a camera-scannable image which will forward to the public tribute link  (works great in a greeting card!).
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How can I export my playlist as a single playable video?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              This feature is coming soon.  In the immediate short-term, please contact us when your playlist is ready to finalize if you would like a single stitched file.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>I'm experiencing playback issues with some videos.</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              Not all devices share the same video standards, so we convert all new uploads to a universally-playable standard, but this process does take a few minutes.
              Please check in your Media Library to verify if the transcoding process has completed for the video in question.
              If you're still experiencing issues with playability after transcoding is finished, please contact us for further assistance.  Please be sure to make a note of the device, operating system and browser which is encountering issues.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I change my email address?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              You can change your email address in the <Link :href="$routes.account()" class="text-primary">
                Profile section
              </Link>.
            </DisclosurePanel>
          </Disclosure>
          <Disclosure v-slot="{ open }">
            <DisclosureButton
              class="flex w-full justify-between rounded-lg bg-purple-100 px-4 py-2 text-left text-sm font-medium text-purple-900 hover:bg-purple-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
            >
              <span>How do I change my password?</span>
              <ChevronUpIcon
                :class="open ? 'rotate-180 transform' : ''"
                class="h-5 w-5 text-purple-500"
              />
            </DisclosureButton>
            <DisclosurePanel class="px-4 pb-2 pt-4 text-sm text-gray-500">
              You can change your password in the <Link :href="$routes.account()" class="text-primary">
                Profile section
              </Link>.
            </DisclosurePanel>
          </Disclosure>
        </div>
      </div>
      <SupportForm v-model="form" @submit="submit">
        <LoadingButton
          :loading="form.processing"
          class="btn-primary ml-auto"
          type="submit"
        >
          Send Message
        </LoadingButton>
      </SupportForm>
    </div>
  </Layout>
</template>

<script setup>
import * as Sentry from "@sentry/browser";
import { Head, useForm, usePage, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import SupportForm from './Form.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue'
import { ChevronUpIcon } from '@heroicons/vue/20/solid'
import { config } from '@/siteConfig'
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';

const form = useForm({
  support: {
    name: `${usePage().props.auth.user.first_name} ${usePage().props.auth.user.last_name}`,
    email: usePage().props.auth.user.email,
    message: ""
  }
})

const submit = () => {
  const eventId = Sentry.captureMessage("User Feedback");
  const userFeedback = {
    name: form.support.name,
    email: form.support.email,
    message: form.support.message,
    associatedEventId: eventId,
  };
  Sentry.captureFeedback(userFeedback);

  form.post($routes.support(), {
    onSuccess: () => {
      form.reset('support');
    },
  });
}
</script>
