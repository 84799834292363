<template>
  <form @submit.prevent="stopRecordingAndSubmit">
    <div class="gap-6 flex w-full p-8 flex-col">
      <TextInput
        v-if="form.upload.url_downloadable"
        v-model="form.upload.name"
        :errors="form.errors.name"
        label="Media Title"
      />
      <TextInput
        v-if="form.upload.content_type.includes('video')"
        v-model="form.upload.thumb_seconds"
        :errors="form.errors.thumb_seconds"
        label="Thumbnail Seconds"
      />
      <div v-if="$page.props.libraryOptions?.length > 1 && !form.upload.url_downloadable">
        <label class="form-label">Destination Media Library</label>
        <CollaborationFilter
          v-model="form.media_library_user_id"
          :filter-options="libraryOptions"
        />
      </div>
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import { computed, ref } from 'vue';
import CollaborationFilter from '@/Shared/CollaborationFilter.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  libraryOptions: {
    type: Array,
    required: false
  }
})

const emit = defineEmits(['update:modelValue', 'submit']);
const recording = ref(false)
const recorder = ref()

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});

const stopRecordingAndSubmit = () => {
  if (recording.value === true) {
    recorder.value.stopRecording()
    setTimeout(() => {
      emit('submit')
    }, 1000)
  } else {
    emit('submit')
  }
}
</script>
