<template>
  <div>
    <div
      class="flex items-center px-2 cursor-pointer"
      title="Delete"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      :title="`Delete ${ !config.pageAlias ? 'Page' : config.pageAlias}`"
      @close="isOpen = false"
    >
      <div class="bg-white flex w-full p-8">
        Are you sure you want to delete "{{ page.name }}"?
      </div>
      <div
        class="flex items-center rounded-b justify-end gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div class="btn-primary cursor-pointer" @click="destroy">
          Delete
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
  import { ref } from 'vue';
  import { router } from '@inertiajs/vue3';
  import * as $routes from '@/routes.js'
  import DefaultModal from '@/Shared/DefaultModal.vue';
  import { config } from '@/siteConfig'

  const props = defineProps({
    page: {
      type: Object,
      required: true
    }
  })
  const isOpen = ref(false)

  const destroy = () => router.delete($routes.page(props.page.hashid))
</script>
