<template>
  <div>
    <button class="flex items-center px-2 text-primary hover:text-primary-700" title="Allocate Storage" @click="isOpen=true">
      <slot />
    </button>
    <DefaultModal
      :open="isOpen"
      :title="`Allocate Storage to ${user.name}`"
      @close="isOpen = false"
    >
      <StorageForm v-model="form" @submit="submit">
        <div
          class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
        >
          <LoadingButton
            :loading="form.processing"
            class="btn-primary"
            type="submit"
          >
            Allocate
          </LoadingButton>
        </div>
      </StorageForm>
    </DefaultModal>
  </div>
</template>
<script setup>
import StorageForm from '../StorageAllocations/Form.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import * as $routes from '../../routes';

const props = defineProps({
  allocated: {
    type: Object,
    required: false
  },
  user: {
    type: Object,
    required: true
  }
})

const isOpen = ref(false)

const form = useForm({
  storage_allocation: {
    ...props.allocated,
    recipient_id: props.user.id
  }
})

const submit = () => {
  if (!props.allocated) {
    form.post($routes.storage_allocations(), {preserveState: false})
  } else {
    form.put($routes.storage_allocation(props.allocated.id), {preserveState: false})
  }
}
</script>
