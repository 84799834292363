<template>
  <form @submit.prevent="$emit('submit')">
    <div class="gap-6 flex flex-wrap w-full p-8">
      <TextInput
        v-model="form.storage_allocation.storage_limit"
        :errors="form.errors.storage_limit"
        type="number"
        label="Storage Minutes"
        class="w-full"
      />
      <div class="text-xs">
        To allocate storage without a defined limit, enter a value of <pre class="inline-block bg-gray-100 rounded font-semibold">-1</pre>
      </div>
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import { computed } from 'vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  }
});

const emit = defineEmits(['update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
</script>
