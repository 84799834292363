<template>
  <div>
    <button class="flex items-center px-2 text-primary hover:text-primary-700" title="Assign Clients" @click="isOpen=true">
      <slot />
    </button>
    <DefaultModal
      :open="isOpen"
      :title="`Assign Clients to ${user.name}`"
      @close="isOpen = false"
    >
      <div class="gap-6 flex flex-wrap w-full p-8">
        <div class="flex flex-col w-full">
          <div v-for="client in $page.props.clients" :key="client.id" class="py-2 flex justify-between items-center hover:bg-gray-100 w-full">
            {{ client.name }}
            <button v-if="!user.clients.find((c)=>c.hashid===client.hashid)" title="Add Client" @click="addClient(client)">
              <UserPlusIcon class="size-6 text-primary" />
            </button>
            <button v-else title="Remove Client" @click="removeClient(user.clients.find((c)=>c.hashid===client.hashid).team_collaborator_id)">
              <UserMinusIcon class="size-6 text-red-700 hover:text-red-600" />
            </button>
          </div>
        </div>
      </div>
    </DefaultModal>
  </div>
</template>

<script setup>
import DefaultModal from '../../Shared/DefaultModal.vue';
import { ref } from 'vue';
import { router } from '@inertiajs/vue3';
import * as $routes from '@/routes';
import { UserPlusIcon, UserMinusIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  user: Object,
  clients: Array,
})
const isOpen = ref(false)

const addClient = (client) => {
  router.post(
    $routes.team_collaborators(),
    {
      team_collaborator: {
        team_id: client.team.id,
        collaborator_id: props.user.id,
        permissions: {
          media: { view: true, manage: false, upload: true },
          playlists: [ { id: "default", view: true, manage: false, upload: true } ]
        }
      }
    }
  )
}

const removeClient = (tc_id) => {
  router.delete(
    $routes.team_collaborator(tc_id)
  )
}
</script>
