<template>
  <StripeElements
    ref="elms"
    v-slot="{ elements }"
    :stripe-key="stripeKey"
    :instance-options="instanceOptions"
    :elements-options="elementsOptions"
  >
    <StripeElement
      ref="payment"
      type="payment"
      class="form-input"
      :elements="elements"
      :options="paymentOptions"
    />
  </StripeElements>
</template>

<script setup>
import { StripeElements, StripeElement } from 'vue-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { ref, onBeforeMount } from 'vue'

const props = defineProps({
  clientSecret: {
    type: String
  }
});
const emit = defineEmits(['success', 'failure']);

const stripeKey = ref( import.meta.env.VITE_STRIPE_PUBLISHABLE_KEY)
const instanceOptions = ref({
  // https://stripe.com/docs/js/initializing#init_stripe_js-options

})
const elementsOptions = ref({
  clientSecret: props.clientSecret,
  paymentMethodCreation: 'manual'
  // https://stripe.com/docs/js/elements_object/create#stripe_elements-options
})
const paymentOptions = ref({
  // https://stripe.com/docs/stripe.js#element-options
})
const stripeLoaded = ref(false)
const payment = ref()
const elms = ref()
const errors = ref({})

onBeforeMount(() => {
  const stripePromise = loadStripe(stripeKey.value)
  stripePromise.then(() => {
    stripeLoaded.value = true
  })
})

const createPaymentMethod = () => {
  errors.value = []
  elms.value.elements.submit()
  elms.value.instance.createPaymentMethod({ elements: elms.value.elements }).then((result) => {
    if (result.error) {
      emit('failure', result.error)
    }
    if (result.paymentMethod) {
      elms.value.instance.confirmCardSetup(props.clientSecret, { payment_method: result.paymentMethod.id }).then(function(result) {
        if (result.error) {
          emit('failure', result.error)
        }
        if (result.setupIntent) {
          emit('success', result.setupIntent)
        }
      });
    }
  })
}

defineExpose({ createPaymentMethod })
</script>
