<template>
  <div>
    <div
      class="flex items-center px-2 cursor-pointer text-primary hover:text-primary-700"
      title="Edit Media"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Edit Media"
      @close="isOpen = false"
    >
      <div class="flex w-full">
        <UploadForm v-model="form" class="w-full" @submit="submit">
          <div
            class="flex items-center justify-end rounded-b border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <LoadingButton
              :loading="form.processing"
              class="btn-primary"
              type="submit"
            >
              Update
            </LoadingButton>
          </div>
        </UploadForm>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import { useForm } from '@inertiajs/vue3';
import * as $routes from '@/routes.js'
import UploadForm from './Form.vue'
import LoadingButton from '../../Shared/LoadingButton.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';

const props = defineProps({
  upload: {
    type: Object,
    required: true,
  },
})
const isOpen = ref(false)

const form = useForm({
  upload: props.upload
})


const submit = () => {
  form.put($routes.upload(props.upload.hashid), {
    onSuccess: () => {
      form.reset('upload');
      isOpen.value = false;
    },
  });
}
</script>
