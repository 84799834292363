<template>
  <div class="border-b border-gray-300 mb-4">
    <ul class="flex flex-wrap -mb-px text-xs md:text-sm font-medium text-center text-secondary-700">
      <li class="me-2">
        <Link
          :href="$routes.playlist(playlist.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.playlist(playlist.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.playlist(playlist.hashid) === $page.url ? 'page' : null"
        >
          Media
        </Link>
      </li>
      <li v-if="canManage && !!$env.VITE_ENABLE_PLAYLIST_CONTRIBUTORS" class="me-2">
        <Link
          :href="$routes.playlist_collaboration(playlist.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.playlist_collaboration(playlist.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.playlist_collaboration(playlist.hashid) === $page.url ? 'page' : null"
        >
          Contributors
        </Link>
      </li>
      <li v-if="canManage" class="me-2">
        <Link
          :href="$routes.edit_playlist(playlist.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.edit_playlist(playlist.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.edit_playlist(playlist.hashid) === $page.url ? 'page' : null"
        >
          Settings
        </Link>
      </li>
      <li class="ml-auto">
        <a
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2 border-transparent text-white bg-tertiary hover:text-tertiary-50 hover:bg-tertiary-400 hover:border-tertiary-300 rounded-t"
          :href="$routes.playlist_view(playlist.hashid)"
          target="_blank"
        >
          <EyeIcon class="size-4 md:size-5 mr-2" />
          Preview
        </a>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { EyeIcon } from '@heroicons/vue/24/outline';

const props = defineProps({
  playlist: {
    type: Object,
    required: true,
  },
})

const canManage = usePage().props.auth.user.hashid === props.playlist.user.hashid || props.playlist.permissions?.manage
</script>
