<template>
  <div>
    <div class="bg-white shadow-xl px-8 pt-4 gap-2 flex flex-col">
      <p class="pb-4 font-bold text-xl text-center">
        {{ playlistTitle }}
      </p>
      <p>To start, please let {{ ownerName }} know who you are</p>
      <form @submit.prevent="$emit('submit')">
        <div class="gap-6 flex flex-wrap w-full py-4">
          <TextInput
            v-model="form.guest.first_name"
            :errors="form.errors.first_name"
            label="First Name"
            class="w-full"
          />
          <TextInput
            v-model="form.guest.last_name"
            :errors="form.errors.last_name"
            label="Last Name"
            class="w-full"
          />
          <TextInput
            v-model="form.guest.email"
            type="email"
            :errors="form.errors.email"
            label="Email"
            class="w-full"
          />
        </div>
      </form>
      <p class="text-xs pb-4">
        By submitting media, you signify your agreement to our
        <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">
          terms of use
        </a> and
        <a class="text-primary hover:text-primary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">
          privacy policy
        </a>
      </p>
    </div>
    <div>
      <div class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4">
        <button class="btn-primary ml-auto" @click="$emit('submit')">
          Get Started
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import { computed } from 'vue';

const props = defineProps({
  playlistTitle: String,
  modelValue: {
    type: Object,
    required: true,
  },
  ownerName: String
});

const emit = defineEmits(['update:modelValue', 'submit']);

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
</script>
