<template>
  <div>
    <button class="btn-primary" @click="isOpen = true">
      Add to Gallery
    </button>
    <DefaultModal
      :open="isOpen"
      title="Add to Gallery"
      @close="isOpen = false"
    >
      <div class="flex flex-col w-full p-8">
        <Combobox v-if="!create" v-slot="{open}" v-model="selectedGallery">
          <label class="form-label">Select Gallery</label>
          <div class="relative">
            <div
              class="relative w-full cursor-default overflow-hidden rounded bg-white text-left border border-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75 focus-visible:ring-offset-2 focus-visible:ring-offset-primary-300"
            >
              <ComboboxInput
                class="w-full border-none py-2.5 pl-2 pr-10 focus:ring-0"
                :display-value="(pick) => open ? '' : pick?.name"
                :placeholder="'Start Typing to Search'"
                @change="() => query = $event.target.value"
              />
              <ComboboxButton
                class="absolute inset-y-0 w-full flex items-center pr-2"
              >
                <ChevronUpDownIcon
                  class="size-5 text-gray-400 absolute right-2"
                  aria-hidden="true"
                />
              </ComboboxButton>
            </div>
            <TransitionRoot
              leave="transition ease-in duration-100"
              leave-from="opacity-100"
              leave-to="opacity-0"
              @after-leave="() => query = ''"
            >
              <ComboboxOptions
                class="absolute mt-1 max-h-48 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black/5 focus:outline-none sm:text-sm"
              >
                <div
                  v-if="filteredGalleries.length === 0 && query !== ''"
                  class="relative cursor-default select-none px-4 py-2 text-gray-700"
                >
                  Nothing found.
                </div>

                <ComboboxOption
                  v-for="gallery in filteredGalleries"
                  :key="gallery.id"
                  v-slot="{ selected, active }"
                  as="template"
                  :value="gallery"
                >
                  <li
                    class="relative cursor-default select-none py-2 pl-10 pr-4"
                    :class="{
                      'bg-primary text-white': active,
                      'text-gray-900': !active,
                    }"
                  >
                    {{ gallery.name }}
                    <span
                      v-if="selected"
                      class="absolute inset-y-0 left-0 flex items-center pl-3"
                      :class="{ 'text-white': active, 'text-primary': !active }"
                    >
                      <CheckIcon class="size-5" aria-hidden="true" />
                    </span>
                  </li>
                </ComboboxOption>
              </ComboboxOptions>
            </TransitionRoot>
          </div>
        </Combobox>
        <div v-if="form.errors?.gallery?.length" class="form-error">
          Gallery {{ form.errors?.gallery[0] }}
        </div>
        <TextInput v-if="!!create" v-model="createForm.gallery.name" :errors="createForm.errors.name" label="Gallery Title" />
      </div>
      <div
        class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div v-if="!create && !!$page.props.auth.user.storage_plan" class="cursor-pointer text-primary-700" @click="create = true">
          Create Gallery
        </div>
        <div v-if="!!create" class="cursor-pointer text-primary-700" @click="create = false">
          Select Gallery
        </div>
        <div class="btn-primary cursor-pointer" @click="submit">
          Add
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { computed, ref, watch } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes.js'
import {
  Combobox,
  ComboboxButton,
  ComboboxInput,
  ComboboxOption,
  ComboboxOptions,
  TransitionRoot,
} from '@headlessui/vue';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/vue/20/solid';
import TextInput from '@/Shared/TextInput.vue';

const props = defineProps({
  upload: {
    type: Object,
    required: true
  }
})
const galleries = usePage().props.galleries.data
const selectedGallery = ref(galleries.find((gallery) => gallery.selected === true))
const query = ref('')
const isOpen = ref(false);
const create = ref(!galleries.length);

const filteredGalleries = computed(() =>
  query.value === ''
    ? galleries
    : galleries.filter((gallery) =>
      gallery.name
        .toLowerCase().replace(/\s+/g, '')
        .includes(query.value.toLowerCase().replace(/\s+/g, ''))
    )
)

const form = useForm({
  gallery_upload: {
    upload_id: props.upload.id,
    gallery_id: null
  }
})

const createForm = useForm({
  gallery: {
    name: '',
    upload_id: props.upload.id,
  }
})

watch([selectedGallery], () => {
  form.gallery_upload.gallery_id = selectedGallery.value?.id
});

const complete = () => {
  form.reset()
  createForm.reset()
  selectedGallery.value = null
  isOpen.value = false
}
const submit = () => {
  if (!create.value) {
    form.post($routes.gallery_uploads(),
      {
        onSuccess: () => complete()
      })
  } else {
    createForm.post($routes.galleries(), {
      onSuccess: () => complete()
    })
  }
}
</script>
