<template>
  <Layout>
    <Head :title="pageTitle" />
    <FlashMessages />
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <StepsNavigation :started />
        <template v-if="!started">
          <GuestForm
            :playlist-title="playlist.title"
            :owner-name="`${user.first_name} ${user.last_name}`"
            :model-value="form"
            @submit="start"
          />
        </template>
        <template v-else>
          <div class="bg-white rounded-b">
            <div class="gap-6 flex w-full p-8 flex-col">
              <p class="pb-4 font-bold text-xl text-center">
                {{ playlist.title }}
              </p>
              <p class="text-sm text-green-700 text-center">
                {{ displayMessage }}
              </p>
              <DropZone
                ref="dropZone"
                :form-data="formData"
                :multiple="false"
                :route="$routes.public_upload()"
                @processing="dzProcessing = true"
                @success="dzProcessing = false"
                @ready="(count) => readyCount = count"
                @error="onError"
              />
              <div v-show="errorCount > 0" class="text-sm text-red-700">
                Something went wrong.  Please retry.
              </div>
            </div>
            <div
              v-show="readyCount > 0"
              class="flex items-center justify-between border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
            >
              <LoadingButton :loading="dzProcessing" class="btn-primary ml-auto" @click="submit">
                {{ dzProcessing ? 'Uploading' : `Upload ${readyCount} Media` }}
              </LoadingButton>
            </div>
          </div>
        </template>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import FlashMessages from '@/Shared/FlashMessages.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import Logo from '@/Shared/Logo.vue';
import * as $routes from '@/routes';
import { ref } from 'vue';
import DropZone from '../../Shared/DropZone.vue';
import { useCookies } from '@vueuse/integrations/useCookies';
import StepsNavigation from './Components/StepsNavigation.vue';
import GuestForm from './Components/GuestForm.vue';

const cookies = useCookies(['locale']);
const props = defineProps({
  playlist: Object,
  user: Object,
  media: Array,
  invitation: Object,
});
const dropZone = ref();
const dzProcessing = ref(false);
const started = ref(false);
const displayMessage = ref('');
const readyCount = ref(0);
const errorCount = ref(0)

const pageTitle = `${props.playlist.title} | ${props.user.first_name} ${props.user.last_name} | Quick Upload`;

const formData = {
  upload: {},
  playlist_id: props.playlist.id,
  signed_blob_id: null,
  first_name: cookies.get('_guest_id')?.first_name,
  last_name: cookies.get('_guest_id')?.last_name,
  email: cookies.get('_guest_id')?.email,
};

if (usePage().props.flash.success) {
  started.value = true;
  displayMessage.value = 'Thanks for your contribution! Please feel free to add another.';
}

const submit = () => {
  errorCount.value = 0
  dropZone.value.submit();
};

const start = () => {
  cookies.set('_guest_id', JSON.stringify({
    first_name: form.guest.first_name,
    last_name: form.guest.last_name,
    email: form.guest.email,
  }));
  formData.email = form.guest.email;
  formData.first_name = form.guest.first_name;
  formData.last_name = form.guest.last_name;
  started.value = true;
};
/*
const timer = setInterval(() => {
  if (false) {
    router.reload({ preserveState: true, preserveScroll: true });
  }
}, 1000);

onUnmounted(() => {
  clearInterval(timer);
});
*/
const form = useForm({
  guest: {
    first_name: cookies.get('_guest_id')?.first_name,
    last_name: cookies.get('_guest_id')?.last_name,
    email: cookies.get('_guest_id')?.email,
  },
});

const onError = () => {
  dzProcessing.value = false
  errorCount.value++
}
</script>
