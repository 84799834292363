let config;

const siteConfigs = {
  'hff': {
    enableClients: false,
    enableScreenRecord: false,
    enableOrders: false,
    enablePlaylistDueDate: true,
    enablePlaylistContributors: true,
    enableAdSupported: true,
    freePlaylists: 1,
    freePages: 1,
    freeStorage: 5,
    pageAlias: 'Tribute'
  },
  'rt': {
    enableClients: true,
    enableScreenRecord: false,
    enableOrders: true,
    enablePlaylistDueDate: false,
    enablePlaylistContributors: false,
    enableAdSupported: false,
    freePlaylists: -1,
    freePages: -1,
    freeStorage: 0,
    pageAlias: false
  },
  'esr': {
    enableClients: true,
    enableScreenRecord: false,
    enableOrders: true,
    enablePlaylistDueDate: false,
    enablePlaylistContributors: false,
    enableAdSupported: false,
    freePlaylists: -1,
    freePages: -1,
    freeStorage: 0,
    pageAlias: false
  },
}

config = siteConfigs[import.meta.env.VITE_APP_ID]

export { config }
