<template>
  <Layout>
    <Head :title="gallery.name" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="$routes.galleries()"
      >
        Galleries
      </Link>
      <span class="font-medium text-primary-400">/</span>
      {{ gallery.name }}
    </h1>

    <GalleryMenu :gallery />

    <div class="flex flex-row justify-between my-4 items-center">
      <h2 class="text-2xl font-bold">
        Gallery Media
      </h2>
      <AddToGallery v-if="canUpload" :gallery @success="isOpen = false">
        <button class="btn-primary">
          Add Media
        </button>
      </AddToGallery>
    </div>

    <div class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead v-if="uploads.length">
          <tr class="text-left font-bold text-xs md:text-base">
            <th v-if="canManage" class="px-2 md:px-6 py-4 w-12">
              Order
            </th>
            <th class="px-2 md:px-6 py-4" />
            <th class="px-2 md:px-6 py-4">
              Title
            </th>
          </tr>
        </thead>

        <Draggable
          v-if="canManage"
          v-model="uploads"
          item-key="id"
          :move="checkMove"
          ghost-class="bg-gray-300"
          handle=".handle"
          tag="tbody"
          @start="drag=true"
          @end="reOrder"
        >
          <template #item="{element}">
            <tr class="focus-within:bg-gray-100 hover:bg-gray-100">
              <td class="border-t">
                <div class="flex items-center px-2 md:px-6 py-4">
                  <ArrowsUpDownIcon class="size-6 handle cursor-move text-primary hover:text-primary-700" />
                </div>
              </td>
              <td class="border-t w-12">
                <div class="relative group bg-black cursor-pointer w-12">
                  <img :alt="element.upload.name" :src="element.upload.url_downloadable" class="object-cover size-12">
                </div>
              </td>
              <td class="border-t">
                <div class="flex items-center px-2 md:px-6 py-4 flex-col md:flex-row gap-x-2">
                  <span>{{ element.upload.name }}</span>
                  <span
                    v-if="media.find(m=>m.hashid === element.upload.hashid)?.author?.hashid !== $page.props.auth.user.hashid"
                    class="py-2 text-xs"
                  >
                    by {{ media.find(m=>m.hashid === element.upload.hashid)?.author?.name }}
                  </span>
                </div>
              </td>
              <td class="border-t" />
              <td class="w-px border-t">
                <div class="flex flex-row px-2">
                  <a :href="element.upload.url_downloadable" class="px-2 text-quaternary-600 hover:text-quaternary-800 cursor-pointer" download>
                    <CloudArrowDownIcon class="size-6" />
                  </a>
                  <DestroyUpload v-if="canManage" :gallery-upload="element">
                    <TrashIcon class="size-6 text-red-700 hover:text-red-600" />
                  </DestroyUpload>
                </div>
              </td>
            </tr>
          </template>
        </Draggable>
        <template v-else>
          <tr v-for="element in uploads" :key="element.id">
            <td class="border-t w-12 px-2 py-1">
              <div class="relative group bg-black cursor-pointer w-12">
                <img :alt="element.upload.name" :src="element.upload.url_downloadable" class="object-cover size-12">
              </div>
            </td>
            <td class="border-t">
              <div class="flex items-center px-6 py-4">
                {{ element.upload.name }}
              </div>
            </td>
          </tr>
        </template>
        <tr v-if="gallery.gallery_uploads.length === 0">
          <td class="border-t px-6 py-4" colspan="4">
            No media found in gallery.
          </td>
        </tr>
      </table>
    </div>
  </Layout>
</template>

<script setup>
import Draggable from 'vuedraggable'
import { Head, Link, router, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue'
import { ref } from 'vue';
import { ArrowsUpDownIcon, CloudArrowDownIcon, TrashIcon } from '@heroicons/vue/24/outline';
import * as $routes from '@/routes';
import DestroyUpload from '../GalleryUploads/Destroy.vue';
import GalleryMenu from './Components/GalleryMenu.vue';
import AddToGallery from '../GalleryUploads/NewFromGallery.vue';

const props = defineProps({
  gallery: {
    type: Object,
    required: true,
  },
  media: {
    type: Array,
    required:false
  }
})

const uploads = ref(props.gallery.gallery_uploads)
const isOpen = ref(false)
const drag = ref(false)
const putData = ref({})
const checkMove = (e) => {
  putData.value.id = e.draggedContext.element.id
  putData.value.position = e.draggedContext.futureIndex + 1
}
const reOrder = () => {
  router.put($routes.gallery_upload(putData.value.id), { position: putData.value.position  })
}

const canManage = usePage().props.auth.user.hashid === props.gallery.user.hashid || props.gallery.permissions?.manage
const canUpload = usePage().props.auth.user.hashid === props.gallery.user.hashid || props.gallery.permissions?.upload
</script>
