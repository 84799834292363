<template>
  <Layout>
    <Head title="Media Library" />
    <h1 class="mb-4 text-3xl font-bold">
      Media Library
    </h1>
    <div class="mb-6 flex flex-wrap items-end justify-between gap-2">
      <SearchFilter
        v-model="search"
        class="w-full max-w-xs min-w-28"
        @reset="reset"
      />
      <div v-if="filterOptions.length > 1" class="flex flex-col">
        <span class="form-label">Filter</span>
        <CollaborationFilter v-model="filter" :filter-options />
      </div>
      <button
        :class="!!favorites ? 'bg-primary-600 hover:bg-primary-400' : ''"
        class="btn-secondary hidden xl:flex"
        @click="favorites = !!favorites ? null : true"
      >
        <HeartIconSolid class="size-5 mr-1" :class="!!favorites ? 'text-secondary' : ''" /> Favorites
      </button>
      <div class="flex-col hidden xl:flex">
        <label class="form-label">Sort</label>
        <div class="flex">
          <button
            class="w-28 whitespace-nowrap rounded-theme-l px-5 py-3 text-sm font-bold text-white flex flex-row items-center justify-between"
            :class="orderBy === 'created_at' ? 'bg-tertiary-500' : 'bg-tertiary-400 hover:bg-tertiary-500'"
            @click="toggleOrder('created_at')"
          >
            Date
            <ChevronUpIcon v-if="orderBy === 'created_at' && order === 'asc'" class="size-4" />
            <ChevronDownIcon v-if="orderBy === 'created_at' && order === 'desc'" class="size-4" />
          </button>
          <button
            class="w-28 whitespace-nowrap rounded-theme-r px-5 py-3 text-sm font-bold text-white flex flex-row items-center justify-between"
            :class="orderBy === 'name' ? 'bg-tertiary-500' : 'bg-tertiary-400 hover:bg-tertiary-500'"
            @click="toggleOrder('name')"
          >
            Name
            <ChevronUpIcon v-if="orderBy === 'name' && order === 'asc'" class="size-4" />
            <ChevronDownIcon v-if="orderBy === 'name' && order === 'desc'" class="size-4" />
          </button>
        </div>
      </div>
      <div class="hidden xl:flex flex-col">
        <label class="form-label">View</label>
        <div class="flex">
          <button
            class="whitespace-nowrap rounded-theme-l px-6 py-3 text-sm font-bold text-white"
            :class="view === 'grid' ? 'bg-tertiary-500 cursor-default' : 'bg-tertiary-400 hover:bg-tertiary-500'"
            href="#"
            title="Grid"
            @click="() => view = 'grid'"
          >
            <Squares2X2Icon class="size-5" />
          </button>
          <button
            class="whitespace-nowrap rounded-theme-r px-6 py-3 text-sm font-bold text-white"
            :class="view === 'list' ? 'bg-tertiary-500 cursor-default' : 'bg-tertiary-400 hover:bg-tertiary-500'"
            href="#"
            title="List"
            @click="() => view = 'list'"
          >
            <ListBulletIcon class="size-5" />
          </button>
        </div>
      </div>

      <Popover class="relative xl:hidden">
        <PopoverButton class="whitespace-nowrap rounded px-6 py-2.5 text-sm font-bold text-white bg-tertiary-400 hover:bg-tertiary">
          <AdjustmentsHorizontalIcon class="size-6" />
        </PopoverButton>

        <transition
          enter-active-class="transition duration-200 ease-out"
          enter-from-class="translate-y-1 opacity-0"
          enter-to-class="translate-y-0 opacity-100"
          leave-active-class="transition duration-150 ease-in"
          leave-from-class="translate-y-0 opacity-100"
          leave-to-class="translate-y-1 opacity-0"
        >
          <PopoverPanel
            class="absolute left-0 z-50 bg-white rounded shadow p-2 flex flex-col gap-2"
          >
            <div>
              <button
                :class="!!favorites ? 'bg-primary-600 hover:bg-primary-400' : ''"
                class="btn-secondary flex"
                @click="favorites = !!favorites ? null : true"
              >
                <HeartIconSolid class="size-5 mr-1" :class="!!favorites ? 'text-secondary' : ''" /> Favorites
              </button>
            </div>

            <div class="flex-col flex">
              <label class="form-label">Sort</label>
              <div class="flex">
                <button
                  class="w-28 whitespace-nowrap rounded-theme-l px-5 py-3 text-sm font-bold text-white flex flex-row items-center justify-between"
                  :class="orderBy === 'created_at' ? 'bg-tertiary-500' : 'bg-tertiary-400 hover:bg-tertiary-500'"
                  @click="toggleOrder('created_at')"
                >
                  Date
                  <ChevronUpIcon v-if="orderBy === 'created_at' && order === 'asc'" class="size-4" />
                  <ChevronDownIcon v-if="orderBy === 'created_at' && order === 'desc'" class="size-4" />
                </button>
                <button
                  class="w-28 whitespace-nowrap rounded-theme-r px-5 py-3 text-sm font-bold text-white flex flex-row items-center justify-between"
                  :class="orderBy === 'name' ? 'bg-tertiary-500' : 'bg-tertiary-400 hover:bg-tertiary-500'"
                  @click="toggleOrder('name')"
                >
                  Name
                  <ChevronUpIcon v-if="orderBy === 'name' && order === 'asc'" class="size-4" />
                  <ChevronDownIcon v-if="orderBy === 'name' && order === 'desc'" class="size-4" />
                </button>
              </div>
            </div>
            <div class="flex flex-col">
              <label class="form-label">View</label>
              <div class="flex">
                <button
                  class="whitespace-nowrap rounded-theme-l px-6 py-3 text-sm font-bold text-white"
                  :class="view === 'grid' ? 'bg-tertiary-500 cursor-default' : 'bg-tertiary-400 hover:bg-tertiary-500'"
                  href="#"
                  title="Grid"
                  @click="() => view = 'grid'"
                >
                  <Squares2X2Icon class="size-5" />
                </button>
                <button
                  class="whitespace-nowrap rounded-theme-r px-6 py-3 text-sm font-bold text-white"
                  :class="view === 'list' ? 'bg-tertiary-500 cursor-default' : 'bg-tertiary-400 hover:bg-tertiary-500'"
                  href="#"
                  title="List"
                  @click="() => view = 'list'"
                >
                  <ListBulletIcon class="size-5" />
                </button>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </Popover>
      <button v-if="canUpload" class="btn-primary" @click="isOpen = true">
        Add <span class="hidden md:inline">Media</span>
      </button>
      <Link v-else :href="$routes.subscriptions()" class="btn-primary">
        Add <span class="hidden md:inline">Media</span>
      </Link>
    </div>

    <template v-if="uploads.data.length === 0">
      <div class="rounded bg-white shadow px-6 py-4">
        No media to show.
      </div>
    </template>
    <template v-else>
      <ul v-if="view === 'grid'" class="auto-cols-max gap-4 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 list-none">
        <Upload v-for="upload in uploads.data" :key="upload.id" :upload="upload" :view="view" />
      </ul>
      <div v-else class="rounded bg-white shadow">
        <table class="w-full whitespace-nowrap">
          <tbody>
            <Upload v-for="upload in uploads.data" :key="upload.id" :upload="upload" :view="view" />
          </tbody>
        </table>
      </div>
    </template>
    <Pagination v-if="uploads.meta.last > 1" :meta="uploads.meta" />
    <DefaultModal
      :open="isOpen"
      title="Add Media"
      @close="isOpen = false"
    >
      <NewUpload @success="isOpen = false" />
    </DefaultModal>
  </Layout>
</template>

<script setup>
import { Head, router, usePage, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import Pagination from '@/Shared/Pagination.vue';
import SearchFilter from '@/Shared/SearchFilter.vue';
import { onUnmounted, ref, watch } from 'vue';
import { debounce } from "vue-debounce";
import Upload from './Upload.vue';
import NewUpload from './New.vue';
import {
  Squares2X2Icon,
  ListBulletIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  AdjustmentsHorizontalIcon,
} from '@heroicons/vue/24/outline';
import { HeartIcon as HeartIconSolid } from '@heroicons/vue/24/solid';
import CollaborationFilter from '@/Shared/CollaborationFilter.vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import { config } from '@/siteConfig'

const props = defineProps({
  uploads: {
    type: Object,
    required: true,
  },
  playlists: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  }
})

let search = ref(props.filters.q?.name_cont ?? '');
let view = ref('grid')

let isOpen = ref(!!usePage().props.errors);
let favorites = ref(null);
const orderBy = ref('created_at')
const order = ref('desc')
const filter = ref(props.filterOptions.length ? props.filterOptions.find((person) => person.selected === true).value : [])
const submitDebounce = debounce(() => submitSearch(), 600);

const toggleOrder = (from) => {
  if (orderBy.value === from) {
    order.value === 'asc' ? order.value = 'desc' : order.value = 'asc'
  } else {
    orderBy.value = from
  }
}

watch([search], () => {
  submitDebounce();
});

watch([favorites, orderBy, order, filter], () => {
  submitSearch();
});

const submitSearch = () => router.reload({
  data: {
    q: {
      name_cont: search.value,
      favorite_true: favorites.value,
      s: `${orderBy.value} ${order.value}`
    },
    filter: filter.value
  },
  preserveState:true,
  preserveScroll:true
});

const reset = () => search.value = ''

const canUpload = parseInt(config.freePlaylists) !== 0 ||
  (usePage().props.auth.user.storage_sub && usePage().props.auth.user.minutes_used < usePage().props.auth.user.total_minutes ) ||
  usePage().props.libraryOptions?.length ||
  (usePage().props.auth.user.company && usePage().props.auth.user.company?.user_id !== usePage().props.auth.user.id)

let timer = setInterval(() => {
  if (props.uploads?.data?.filter((u) => !u.processed && u.content_type.includes('video'))?.length) {
    router.reload({
      only: ['uploads'], preserveState: true, preserveScroll: true
    })
  }
}, 60000);

onUnmounted(() => {
  clearInterval(timer);
});
</script>
