<template>
  <div v-show="shouldShowAd" class="bg-white text-black text-center text-xs py-1">
    Advertisement
    <div id="ad-container" />
    <Link :href="$routes.subscriptions()" class="text-green-700">
      Upgrade to Remove Ads
    </Link>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { onMounted } from 'vue';
import * as $routes from '../routes';

const pageProps = usePage().props;
const user = pageProps.auth.user;

const shouldShowAd = (!user.storage_plan && !user.company) || user.storage_plan?.ad_supported;

const loadAd = () => {
  if (shouldShowAd) {
    let adContainer = document.getElementById('ad-container');
    if (adContainer) {
      let adElement = document.createElement('ins');
      adElement.className = 'adsbygoogle';
      adElement.style.display = 'block';
      adElement.setAttribute('data-ad-client', 'ca-pub-1102490854053247');
      adElement.setAttribute('data-ad-slot', '2269324439');
      adElement.setAttribute('data-adtest', 'on');
      adElement.setAttribute('data-ad-format', 'auto');
      adElement.setAttribute('data-full-width-responsive', 'true');
      adContainer.appendChild(adElement);
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }
  }
};

onMounted(() => {
  loadAd();
});
</script>
