<template>
  <form class="lg:max-w-96" @submit.prevent="$emit('submit')">
    <h2 class="mb-4 text-xl font-semibold">
      Contact Customer Support
    </h2>
    <div class="bg-white rounded shadow p-4">
      <div class="w-full">
        <label class="form-label">Your Message</label>
        <TextareaInput v-model="form.support.message" class="w-full" />
        <div class="text-xs mb-4 mt-1 pr-6">
          If you're reporting an issue, please describe what you expected and went wrong.
        </div>
      </div>
      <slot />
    </div>
  </form>
</template>

<script setup>
import { computed } from 'vue';
import TextareaInput from '../../Shared/TextareaInput.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue', 'submit']);

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});
</script>
