<template>
  <div class="border-b border-gray-300 mb-4">
    <ul class="flex flex-wrap -mb-px text-xs md:text-sm font-medium text-center text-secondary-700">
      <li class="me-2">
        <Link
          :href="$routes.gallery(gallery.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.gallery(gallery.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.gallery(gallery.hashid) === $page.url ? 'page' : null"
        >
          Media
        </Link>
      </li>
      <li v-if="canManage" class="me-2">
        <Link
          :href="$routes.edit_gallery(gallery.hashid)"
          class="inline-flex items-center justify-center p-2 md:p-4 border-b-2"
          :class="
            $routes.edit_gallery(gallery.hashid) === $page.url ? 'text-secondary-700 border-secondary-700' : 'border-transparent hover:text-secondary-600 hover:border-secondary-600'
          "
          :aria-current="$routes.edit_gallery(gallery.hashid) === $page.url ? 'page' : null"
        >
          Settings
        </Link>
      </li>
    </ul>
  </div>
</template>

<script setup>
import { Link, usePage } from '@inertiajs/vue3';

const props = defineProps({
  gallery: Object,
})

const canManage = usePage().props.auth.user.hashid === props.gallery.user.hashid || props.gallery.permissions?.manage
</script>
