<template>
  <Layout>
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <Head title="Rest Password" />
      <div class="w-full max-w-md">
        <Logo class="mx-auto block w-full max-w-xs h-24" />

        <div class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl">
          <div class="flex flex-wrap gap-4 justify-between">
            <div class="max-w-3xl overflow-hidden rounded bg-white shadow w-full md:w-auto">
              <ChangePassword v-model="formPw" @submit="submitPw">
                <div
                  class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
                >
                  <LoadingButton
                    :loading="formPw.processing"
                    class="btn-primary ml-auto"
                    type="submit"
                  >
                    Reset Password
                  </LoadingButton>
                </div>
              </ChangePassword>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import ChangePassword from './ChangePassword.vue';
import * as $routes from '@/routes';
import Logo from '../../Shared/Logo.vue';

const props = defineProps({
  user: {
    type: Object,
    required: true,
  },
  // eslint-disable-next-line vue/prop-name-casing
  reset_password_token: {
    type: String,
    required: true
  }
})


const formPw = useForm({
  user: {
    password:null,
    password_confirmation: null,
    reset_password_token: props.reset_password_token
  },
})



const submitPw = () => {
  formPw.put($routes.user_password(), {
    onSuccess: () => formPw.reset(),
  });
}

</script>
