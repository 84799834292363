<template>
  <button
    class="flex items-center text-primary focus:text-primary-700 hover:text-primary-600"
    title="Permissions"
    @click="modalPermissions = true"
  >
    <slot />
  </button>
  <DefaultModal
    :open="modalPermissions"
    :title="`Collaborator Permissions: ${invitation.first_name} ${invitation.last_name}`"
    @close="modalPermissions = false"
  >
    <Permissions :invitation @success="modalPermissions = false" />
  </DefaultModal>
</template>
<script setup>
import Permissions from './PermissionsForm.vue';
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';

defineProps({
  invitation: {
    type: Object,
    required: true,
  },
})
const modalPermissions = ref(false);
</script>
