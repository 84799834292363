import defaultTheme from 'tailwindcss/defaultTheme';

const themes = {
  hff: {
    brand: {
      DEFAULT: '#DAA520',
      50: '#F5E5BC',
      100: '#F3DEAB',
      200: '#EDD087',
      300: '#E8C263',
      400: '#E3B440',
      500: '#DAA520',
      600: '#A98019',
      700: '#785B12',
      800: '#47360A',
      900: '#161103',
      950: '#000000'
    },
    primary: {
      DEFAULT: '#3452FF',
      50: '#ECEEFF',
      100: '#D7DDFF',
      200: '#AEBAFF',
      300: '#8698FF',
      400: '#5D75FF',
      500: '#3452FF',
      600: '#0025FB',
      700: '#001DC3',
      800: '#00148B',
      900: '#000C53',
      950: '#000837'
    },
    secondary: {
      DEFAULT: '#FF3269',
      50: '#FFEAEF',
      100: '#FFD5E0',
      200: '#FFACC3',
      300: '#FF84A5',
      400: '#FF5B87',
      500: '#FF3269',
      600: '#F90043',
      700: '#C10034',
      800: '#890025',
      900: '#510016',
      950: '#35000E'
    },
    tertiary: {
      DEFAULT: '#5B8E7D',
      50: '#C6DBD4',
      100: '#BAD3CA',
      200: '#A1C3B7',
      300: '#88B3A4',
      400: '#6FA392',
      500: '#5B8E7D',
      600: '#456C5F',
      700: '#2F4A41',
      800: '#192723',
      900: '#030505',
      950: '#000000'
    },
    quaternary: {
      DEFAULT: '#AFC2D5',
      50: '#FFFFFF',
      100: '#FFFFFF',
      200: '#FFFFFF',
      300: '#E5EBF1',
      400: '#CAD6E3',
      500: '#AFC2D5',
      600: '#8AA6C2',
      700: '#658AAE',
      800: '#4C6E90',
      900: '#38526B',
      950: '#2F4459'
    },
    quinary: {},
    fontFamily: {
      sans: ['Open Sans', ...defaultTheme.fontFamily.sans]
    },
    rounded: '0.25rem'
  },
  rt: {
    brand: {
      DEFAULT: '#1779F7',
      50: '#C8E0FD',
      100: '#B5D4FC',
      200: '#8DBDFB',
      300: '#66A7FA',
      400: '#3E90F8',
      500: '#1779F7',
      600: '#075ECF',
      700: '#054699',
      800: '#032D62',
      900: '#02142C',
      950: '#010811'
    },
    primary: {
      DEFAULT: '#09CAED',
      50: '#B2F1FC',
      100: '#9EEDFB',
      200: '#77E6FA',
      300: '#4FDEF8',
      400: '#28D7F7',
      500: '#09CAED',
      600: '#079CB7',
      700: '#056E81',
      800: '#03404B',
      900: '#011215',
      950: '#000000'
    },
    secondary: {
      DEFAULT: '#1779F7',
      50: '#C8E0FD',
      100: '#B5D4FC',
      200: '#8DBDFB',
      300: '#66A7FA',
      400: '#3E90F8',
      500: '#1779F7',
      600: '#075ECF',
      700: '#054699',
      800: '#032D62',
      900: '#02142C',
      950: '#010811'
    },
    tertiary: {
      DEFAULT: '#5B0DBA',
      50: '#BA88F7',
      100: '#AF75F5',
      200: '#994FF3',
      300: '#8329F0',
      400: '#6E10E0',
      500: '#5B0DBA',
      600: '#410986',
      700: '#280651',
      800: '#0E021D',
      900: '#000000',
      950: '#000000'
    },
    quaternary: {
      DEFAULT: '#E7612C',
      50: '#FADCD1',
      100: '#F8CFBF',
      200: '#F4B39A',
      300: '#EF9875',
      400: '#EB7C51',
      500: '#E7612C',
      600: '#C54816',
      700: '#923511',
      800: '#60230B',
      900: '#2D1105',
      950: '#140702'
    },
    fontFamily: {
      sans: ['Spartan', ...defaultTheme.fontFamily.sans]
    },
    rounded: '0px'
  },
  esr: {
    brand: {
      DEFAULT: '#1779F7',
      50: '#C8E0FD',
      100: '#B5D4FC',
      200: '#8DBDFB',
      300: '#66A7FA',
      400: '#3E90F8',
      500: '#1779F7',
      600: '#075ECF',
      700: '#054699',
      800: '#032D62',
      900: '#02142C',
      950: '#010811'
    },
    primary: {
      DEFAULT: '#09CAED',
      50: '#B2F1FC',
      100: '#9EEDFB',
      200: '#77E6FA',
      300: '#4FDEF8',
      400: '#28D7F7',
      500: '#09CAED',
      600: '#079CB7',
      700: '#056E81',
      800: '#03404B',
      900: '#011215',
      950: '#000000'
    },
    secondary: {
      DEFAULT: '#1779F7',
      50: '#C8E0FD',
      100: '#B5D4FC',
      200: '#8DBDFB',
      300: '#66A7FA',
      400: '#3E90F8',
      500: '#1779F7',
      600: '#075ECF',
      700: '#054699',
      800: '#032D62',
      900: '#02142C',
      950: '#010811'
    },
    tertiary: {
      DEFAULT: '#5B0DBA',
      50: '#BA88F7',
      100: '#AF75F5',
      200: '#994FF3',
      300: '#8329F0',
      400: '#6E10E0',
      500: '#5B0DBA',
      600: '#410986',
      700: '#280651',
      800: '#0E021D',
      900: '#000000',
      950: '#000000'
    },
    quaternary: {
      DEFAULT: '#E7612C',
      50: '#FADCD1',
      100: '#F8CFBF',
      200: '#F4B39A',
      300: '#EF9875',
      400: '#EB7C51',
      500: '#E7612C',
      600: '#C54816',
      700: '#923511',
      800: '#60230B',
      900: '#2D1105',
      950: '#140702'
    },
    fontFamily: {
      sans: ['Spartan', ...defaultTheme.fontFamily.sans]
    },
    rounded: '0.25rem'
  }
}
const theme = themes[import.meta.env.VITE_APP_ID] || themes.rt

export { theme }
