<template>
  <tr
    :key="gallery.id"
    class="focus-within:bg-gray-100 hover:bg-gray-100"
  >
    <td class="border-t">
      <Link
        class="flex flex-col px-6 py-4 focus:text-primary-600 hover:text-primary-600 text-primary"
        :href="$routes.gallery(gallery.hashid)"
      >
        <span class="max-w-56 2xl:max-w-prose overflow-hidden text-ellipsis h-5 leading-5">{{ gallery.name }}</span>
        <div v-if="showOwner" class="inline-block lg:hidden text-sm">
          {{ isMine ? 'Me' : gallery.user.name }}
        </div>
        <DateString :date="gallery.created_at" class="inline-block lg:hidden text-sm" />
      </Link>
    </td>


    <td class="w-px border-t">
      <div class="hidden xl:flex flex-row px-2">
        <Link
          v-if="canManage"
          :href="$routes.gallery(gallery.hashid)"
          class="flex items-center px-2 text-primary hover:text-primary-700"
          title="Manage Gallery"
        >
          <PencilIcon class="size-6" />
        </Link>
        <NewFromGallery v-if="canUpload" :gallery>
          <button
            class="flex items-center px-2 text-primary hover:text-primary-700"
          >
            <CloudArrowUpIcon class="size-6" />
          </button>
        </NewFromGallery>
      </div>
      <div class="px-2 flex xl:hidden">
        <Popover class="relative">
          <PopoverButton class="text-primary hover:text-primary-700">
            <EllipsisVerticalIcon class="size-6" />
          </PopoverButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel
              class="absolute right-0 z-50 bg-white rounded shadow p-2 flex flex-row gap-2"
            >
              <Link
                v-if="canManage"
                :href="$routes.gallery(gallery.hashid)"
                class="flex items-center px-2"
                title="Manage Gallery"
              >
                <PencilIcon class="size-6" />
              </Link>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </td>
  </tr>
</template>
<script setup>
import {
  PencilIcon,
  EllipsisVerticalIcon, CloudArrowUpIcon,
} from '@heroicons/vue/24/outline';
import DateString from '@/Shared/DateString.vue';
import { Link, usePage } from '@inertiajs/vue3';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import NewFromGallery from '../GalleryUploads/NewFromGallery.vue';

const props = defineProps({
  gallery: Object,
  permissions: Array,
  showOwner: Boolean,
})

const isMine = props.gallery.user.hashid === usePage().props.auth.user.hashid
const canManage = isMine || (props.permissions?.find((p) => p.id === props.gallery.hashid) ? props.permissions?.find((p) => p.id === props.gallery.hashid)?.manage  : props.permissions?.find((p) => p.id === 'default')?.manage)
const canUpload = isMine || (props.permissions?.find((p) => p.id === props.gallery.hashid) ? props.permissions?.find((p) => p.id === props.gallery.hashid)?.upload  : props.permissions?.find((p) => p.id === 'default')?.upload)
</script>
