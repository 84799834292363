<template>
  <Layout>
    <Head title="Representation" />
    <h1 class="mb-8 text-3xl font-bold">
      Representation
    </h1>
    <ProfileMenu />

    <div class="mb-6 flex items-center justify-between">
      <button class="ml-auto btn-primary" @click="modalNew = true">
        Invite <span class="hidden md:inline">Representation</span>
      </button>

      <DefaultModal
        :open="modalNew"
        title="Invite Representation"
        @close="modalNew = false"
      >
        <NewInvitation @success="modalNew = false" />
      </DefaultModal>
    </div>
    <div class="overflow-x-auto rounded bg-white shadow mb-6">
      <table class="w-full whitespace-nowrap">
        <thead v-if="toAccept.length > 0 || invitations.length > 0 || collaborators.length > 0">
          <tr class="text-left font-bold">
            <th class="px-6 pb-4 pt-6" colspan="3">
              Representative
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="request in toAccept"
            :key="request.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="flex flex-col px-6 py-4 focus:text-primary justify-content-start"
              >
                <div>{{ request.user.first_name }} {{ request.user.last_name }} <span class="text-xs italic text-tertiary">would like to connect</span></div>
                <div class="text-xs">
                  <span class="capitalize">{{ request.user.role }}</span> - {{ request.user.email }}
                </div>
              </div>
            </td>
            <td class="w-px border-t" colspan="3">
              <div class="flex flex-row items-center justify-end px-2">
                <Link
                  :href="`/invitations/${request.code}/accept`"
                  class="text-xs p-1.5 mr-2 bg-primary-600 rounded text-white"
                >
                  Accept
                </Link>
                <DestroyInvitation :invitation="request">
                  <TrashIcon
                    class="size-6 text-red-700"
                  />
                </DestroyInvitation>
              </div>
            </td>
          </tr>
          <tr
            v-for="invite in invitations"
            :key="invite.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="px-6 py-4"
              >
                <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                <div class="text-xs">
                  <span class="capitalize">{{ invite.role }}</span> - {{ invite.email }} <span class="italic text-tertiary">
                    Invitation
                    <template v-if="invite.created_at !== invite.updated_at">re-</template>sent <DateString :date="invite.updated_at" /></span>
                </div>
              </div>
            </td>
            <td class="w-px border-t">
              <div class="flex flex-row px-2">
                <button class="relative mx-2" title="Resend Invitation" @click="resend(invite.code)">
                  <EnvelopeIcon class="size-6 text-primary" />
                  <ArrowPathIcon class="size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                </button>
                <InvitePermissions :invitation="invite">
                  <div class="relative mx-2">
                    <UserIcon class="size-6 text-primary" />
                    <LockClosedIcon class="text-black size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                  </div>
                </InvitePermissions>
                <DestroyInvitation :invitation="invite">
                  <TrashIcon
                    class="size-6 text-red-700"
                  />
                </DestroyInvitation>
              </div>
            </td>
          </tr>
          <tr
            v-for="collaborator in collaborators"
            :key="collaborator.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="flex flex-col px-6 py-4 focus:text-primary justify-content-start"
              >
                <div>{{ collaborator.user.name }}</div>
                <div class="text-xs">
                  <span class="capitalize">{{ collaborator.user.role }}</span> - {{ collaborator.user.email }}
                </div>
              </div>
            </td>
            <td class="w-px border-t">
              <div class="flex justify-end px-2">
                <Permissions :collaborator>
                  <div class="relative ml-auto mx-2">
                    <UserIcon class="size-6 text-primary" />
                    <LockClosedIcon class="text-black size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                  </div>
                </Permissions>
                <DestroyRelationship :team-collaborator="collaborator">
                  <TrashIcon
                    class="size-6 text-red-700 hover:text-red-600 ml-auto"
                  />
                </DestroyRelationship>
              </div>
            </td>
          </tr>
          <tr v-if="collaborators.length === 0 && invitations.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No collaborators found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/Layouts/Main.vue"
import { Head, Link, router } from '@inertiajs/vue3';
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import NewInvitation from '@/Pages/Collaboration/New.vue';
import {
  TrashIcon,
  EnvelopeIcon,
  ArrowPathIcon,
  UserIcon,
  LockClosedIcon,
} from '@heroicons/vue/24/outline';
import DateString from '../../Shared/DateString.vue';
import DestroyInvitation from '../Invitations/Destroy.vue';
import DestroyRelationship from '../TeamCollaborators/Destroy.vue';
import Permissions from './Permissions.vue';
import InvitePermissions from '../Invitations/Permissions.vue';
import ProfileMenu from '../Auth/Components/ProfileMenu.vue';
import * as $routes from '@/routes';

defineProps({
  invitations: {
    type: Array,
    required: true,
  },
  toAccept: {
    type: Array,
    required: true,
  },
  collaborators: {
    type: Array,
    required: true,
  },
  collaborations: {
    type: Array,
    required: true,
  }
})

const modalNew = ref(false);

const resend = (code) => {
  router.visit($routes.invitation_resend_notifications(code))
}
</script>
