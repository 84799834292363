<template>
  <form @submit.prevent="$emit('submit')">
    <div class="gap-6 flex flex-wrap w-full p-8" :class="{'flex-wrap' : quickAdd}">
      <TextInput
        v-model="form.invitation.first_name"
        :errors="form.errors.first_name"
        label="First Name"
        class="w-full sm:w-64"
      />

      <TextInput
        v-model="form.invitation.last_name"
        :errors="form.errors.last_name"
        label="Last Name"
        class="w-full sm:w-64"
      />

      <TextInput
        v-if="quickAdd"
        v-model="form.invitation.email"
        type="email"
        :errors="form.errors.email"
        label="Email"
        class="w-full sm:w-64"
      />
      <template v-if="!quickAdd">
        <TextInput
          v-model="form.invitation.email"
          type="email"
          :errors="form.errors.email"
          label="Email"
          class="w-full sm:w-64"
        />

        <SelectInput
          v-if="!$env.VITE_ENABLE_CLIENTS"
          v-model="form.invitation.permissions"
          :errors="form.errors.permissions"
          label="Permissions"
          class="w-full sm:w-64"
        >
          <option :value="collaborator">
            Collaborator (Default)
          </option>
          <option :value="editor">
            Editor (Full Access)
          </option>
          <option v-if="!hidePlaylistOption" :value="contributor">
            Single Playlist Contributor
          </option>
        </SelectInput>

        <div v-if="!!$env.VITE_ENABLE_CLIENTS && !$page.props.auth.user.business">
          <InputRadio v-model="form.invitation.role" name="invitation_role" value="agent">
            Agent
          </InputRadio>
          <InputRadio v-model="form.invitation.role" name="invitation_role" value="manager">
            Manager
          </InputRadio>
        </div>

        <SelectInput
          v-if="!hidePlaylist"
          v-model="playlist"
          class="w-full sm:w-64"
          label="Playlist"
        >
          <option v-for="p in playlists" :key="p.hashid" :value="p">
            {{ p.name }}
          </option>
        </SelectInput>
      </template>
    </div>
    <slot />
  </form>
</template>

<script setup>
import TextInput from '@/Shared/TextInput.vue';
import SelectInput from '@/Shared/SelectInput.vue';
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import InputRadio from '../../Shared/InputRadio.vue';

const props = defineProps({
  modelValue: {
    type: Object,
    required: true,
  },
  quickAdd: {
    type: Boolean,
    default: false
  },
  playlist: {
    type: Object,
    required: false
  }
});

const emit = defineEmits(['update:modelValue', 'submit'])

const form = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emit('update:modelValue', val)
  }
});

const playlists = usePage().props.playlists  ?? []
const playlist = ref(playlists[0] ?? props.playlist)

const collaborator = {
  media: { view: true, manage: false, upload: true},
  playlists: [ { id: "default", view: true, manage: false, upload: true} ],
  pages: [ { id: "default", view: true, manage: false, upload: true} ],
  galleries: [ { id: "default", view: true, manage: false, upload: true} ]
}

const editor = {
  media: { view: true, manage: true, upload: true},
  playlists: [ { id: "default", view: true, manage: true, upload: true} ],
  pages: [ { id: "default", view: true, manage: true, upload: true} ],
  galleries: [ { id: "default", view: true, manage: true, upload: true} ]
}

const contributor = computed(() => ({
  media: { view: false, manage: false, upload: false},
  playlists: [
    { id: "default", view: false, manage: false, upload: false},
    { id: playlist?.value?.hashid, view: false, manage: false, upload: true}
  ]
}))

const hidePlaylist = computed(() => JSON.stringify(form.value.invitation.permissions) === JSON.stringify(collaborator) || JSON.stringify(form.value.invitation.permissions) === JSON.stringify(editor) || playlists.length === 0)
const hidePlaylistOption = computed(() => playlists.length === 0 && playlist.value === null)

</script>
