<template>
  <div>
    <div
      class="flex items-center cursor-pointer"
      title="Change Plan"
      @click="isOpen = true"
    >
      <slot />
    </div>
    <DefaultModal
      :open="isOpen"
      title="Change Plan"
      max-width="max-w-fit"
      @close="isOpen = false"
    >
      <div v-if="$page.props.customer?.discount?.coupon" class="text-green-700 text-xs mx-auto w-fit p-2">
        <div>{{ $page.props.customer?.discount?.coupon?.name }}</div>
        {{ $page.props.customer?.discount?.coupon?.percent_off ? `${$page.props.customer?.discount?.coupon?.percent_off}%` : '' }}
        {{ $page.props.customer?.discount?.coupon?.amount_off ? `$${$page.props.customer?.discount?.coupon?.amount_off}%` : '' }}
        off
        <template v-if="$page.props.customer?.discount?.coupon?.duration === 'repeating'">
          <DateString :date="$page.props.customer?.discount?.start * 1000" /> -
          <DateString :date="$page.props.customer?.discount?.end * 1000" />
        </template>
      </div>
      <div class="bg-white flex flex-col md:flex-row w-full p-8 gap-4">
        <div>
          <label class="form-label">
            Current plan:
          </label>
          <div
            class="flex flex-col p-6 mx-auto max-w-lg text-center rounded shadow w-full md:w-80"
          >
            <h3 class="mb-4 text-2xl font-semibold">
              {{ $page.props.auth.user.storage_plan.name }}
            </h3>
            <div class="flex flex-col my-8">
              <div class="flex justify-center items-baseline">
                <span class=" text-5xl font-extrabold">
                  <DollarAmount
                    :amount="$page.props.auth.user.storage_sub.processor_plan === $page.props.auth.user.storage_plan.monthly_stripe_price_id ? $page.props.auth.user.storage_plan.monthly_price : $page.props.auth.user.storage_plan.annual_price / 12"
                    :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}"
                  />
                  <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                    <DollarAmount
                      :amount="$page.props.auth.user.storage_sub.processor_plan === $page.props.auth.user.storage_plan.monthly_stripe_price_id ?
                        $page.props.auth.user.storage_plan.monthly_price - Math.floor($page.props.auth.user.storage_plan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                        ($page.props.auth.user.storage_plan.annual_price/12) - Math.floor($page.props.auth.user.storage_plan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                    />
                  </span>
                </span>
                <span class="text-gray-500">/month</span>
              </div>
              <div v-if="$page.props.auth.user.storage_sub.processor_plan === $page.props.auth.user.storage_plan.annual_stripe_price_id" class="text-gray-500 text-xs mt-2">
                billed annually
              </div>
            </div>
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span><span class="font-semibold">{{ $page.props.auth.user.storage_plan.storage_minutes }}</span> minutes of storage</span>
              </li>
              <li class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span v-if="$page.props.auth.user.storage_plan.playlists !== -1">Playlists: <span class="font-semibold">{{ $page.props.auth.user.storage_plan.playlists }}</span></span>
                <span v-else><span class="font-semibold">Unlimited Playlists</span></span>
              </li>
              <li v-if="!!$page.props.auth.user.storage_plan.collaborators" class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span>Collaborators: <span class="font-semibold">{{ $page.props.auth.user.storage_plan.collaborators }}</span></span>
              </li>
              <li v-if="!$page.props.auth.user.storage_plan.ad_supported" class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span>Ad-Free</span>
              </li>
            </ul>
          </div>
        </div>
        <div>
          <label class="form-label">
            New Plan:
          </label>
          <div
            class="flex flex-col p-6 mx-auto max-w-lg text-center rounded shadow w-full md:w-80"
          >
            <PlanSelector v-model="form.stripe_price_id" :plan-options="planOptions()" />
            <div class="flex flex-col my-8">
              <div class="flex justify-center items-baseline">
                <span class=" text-5xl font-extrabold">
                  <DollarAmount
                    :amount="planOptions().find((p)=> p.value === form.stripe_price_id)?.frequency === 'monthly' ? planOptions().find((p)=> p.value === form.stripe_price_id)?.price : planOptions().find((p)=> p.value === form.stripe_price_id)?.price / 12"
                    :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}"
                  />
                  <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                    <DollarAmount
                      :amount="planOptions().find((p)=> p.value === form.stripe_price_id)?.frequency === 'monthly' ?
                        planOptions().find((p)=> p.value === form.stripe_price_id)?.price - Math.floor(planOptions().find((p)=> p.value === form.stripe_price_id)?.price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100:
                        (planOptions().find((p)=> p.value === form.stripe_price_id)?.price/12) - Math.floor(planOptions().find((p)=> p.value === form.stripe_price_id)?.price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                    />
                  </span>
                </span>
                <span class="text-gray-500">/month</span>
              </div>
              <div v-if="planOptions().find((p)=> p.value === form.stripe_price_id)?.frequency === 'annual'" class="text-gray-500 text-xs mt-2">
                billed annually
              </div>
            </div>
            <ul role="list" class="mb-8 space-y-4 text-left">
              <li class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span><span class="font-semibold">{{ planOptions().find((p)=> p.value === form.stripe_price_id)?.storage_minutes }}</span> minutes of storage</span>
              </li>
              <li class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span v-if="planOptions().find((p)=> p.value === form.stripe_price_id)?.playlists !== -1">Playlists:
                  <span class="font-semibold">{{ planOptions().find((p)=> p.value === form.stripe_price_id)?.playlists }}</span>
                </span>
                <span v-else><span class="font-semibold">Unlimited Playlists</span></span>
              </li>
              <li v-if="!!planOptions().find((p)=> p.value === form.stripe_price_id)?.collaborators" class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span>Collaborators: <span class="font-semibold">{{ planOptions().find((p)=> p.value === form.stripe_price_id)?.collaborators }}</span></span>
              </li>
              <li v-if="!planOptions().find((p)=> p.value === form.stripe_price_id)?.ad_supported" class="flex items-center space-x-3">
                <CheckIcon class="size-5 text-green-500" />
                <span>Ad-Free</span>
              </li>
              <li v-else class="flex items-center space-x-3">
                <span>Limited Ads</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div
        class="flex items-center rounded-b justify-end gap-2 border-t border-gray-200 bg-gray-100 px-8 py-4"
      >
        <div class="btn-primary cursor-pointer" @click="update">
          Update Plan
        </div>
      </div>
    </DefaultModal>
  </div>
</template>
<script setup>
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import PlanSelector from '@/Shared/PlanSelector.vue';
import { useForm, usePage } from '@inertiajs/vue3';
import * as $routes from '@/routes';
import { CheckIcon } from '@heroicons/vue/24/outline';
import DollarAmount from '../../Shared/DollarAmount.vue';
import DateString from '../../Shared/DateString.vue';

const isOpen = ref(false)

const planOptions = () => usePage().props.plans.flatMap((p) => (
  [
    { name: p.name, price: p.monthly_price, frequency: 'monthly', storage_minutes: p.storage_minutes, playlists: p.playlists, value: p.monthly_stripe_price_id, collaborators: p.collaborators, ad_supported: p.ad_supported },
    { name: p.name, price: p.annual_price, frequency: 'annual', storage_minutes: p.storage_minutes, playlists: p.playlists, value: p.annual_stripe_price_id, collaborators: p.collaborators, ad_supported: p.ad_supported }
  ]
)).filter(p=>p.value !== usePage().props.auth.user.storage_sub.processor_plan);

const form = useForm({ stripe_price_id:  planOptions()[0]?.value})

const update = () => form.post($routes.subscriptions_upgrade_confirm(), { onSuccess: () => {isOpen.value = false}})
</script>
