<template>
  <Layout>
    <Head title="First Page" />
    <h2 class="mb-4 text-4xl tracking-tight font-extrabold">
      Welcome to {{ $env.VITE_APP_NAME }}!
    </h2>
    <p class="mb-8 font-light text-gray-500 sm:text-xl">
      Let's get started creating your first tribute
    </p>
    <PageForm v-model="form" class="bg-white rounded max-w-prose shadow" @submit="submit">
      <div
        class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
      >
        <LoadingButton
          :loading="form.processing"
          class="btn-primary"
          type="submit"
        >
          Next&nbsp;<ArrowRightIcon class="size-4 inline" />&nbsp;Add Contributors
        </LoadingButton>
      </div>
    </PageForm>
  </Layout>
</template>
<script setup>
import { Head, useForm } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import PageForm from '../Pages/Form.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';
import { ArrowRightIcon } from '@heroicons/vue/24/outline';
import { config } from '@/siteConfig'

const form = useForm({
  page: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    email_content: config.enablePlaylistContributors ? "Please help me create a group video tribute by contributing a recorded greeting or sharing a memory." :  '',
    customizations: { background_color: '#e2e8f0'}
  }
})

const submit = () => {
  form.post($routes.pages());
}
</script>
