<template>
  <Layout>
    <Head title="Galleries" />
    <h1 class="mb-8 text-3xl font-bold">
      Galleries
    </h1>
    <div class="mb-6 flex items-center justify-between gap-4">
      <template v-if="$page.props.auth.user.storage_sub || parseInt($env.VITE_FREE_GALLERIES) === -1 || (parseInt($env.VITE_FREE_GALLERIES) > 0 && galleries.data.length < parseInt($env.VITE_FREE_GALLERIES))">
        <button class="btn-primary" @click="isOpen = true">
          Create <span class="hidden md:inline">Gallery</span>
        </button>
        <DefaultModal
          :open="isOpen"
          title="Create Gallery"
          @close="isOpen = false"
        >
          <NewGallery @success="isOpen = false" />
        </DefaultModal>
      </template>
      <Link v-else :href="$routes.subscriptions()" class="btn-primary">
        Create <span class="hidden md:inline">Gallery</span>
      </Link>
    </div>
    <div class="rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead v-if="galleries.data.length">
          <tr class="text-left font-bold hidden lg:table-row">
            <th class="px-6 pb-4 pt-6">
              Title
            </th>
          </tr>
        </thead>
        <tbody>
          <Gallery
            v-for="gallery in galleries.data"
            :key="gallery.id"
            :gallery
          />
          <tr v-if="galleries.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No galleries found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination v-if="galleries.meta.last > 1" :meta="galleries.meta" />
  </Layout>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import Gallery from '../Galleries/Gallery.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';
import NewGallery from './New.vue'
import Pagination from '../../Shared/Pagination.vue';
import { ref } from 'vue';

defineProps({
  galleries: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
  collaborations: {
    type: Array,
    required: false
  }
})

const isOpen = ref(false);
</script>
