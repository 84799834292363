<template>
  <Layout>
    <Head :title="!config.pageAlias ? 'Pages' : `${config.pageAlias}s`" />
    <h1 class="mb-8 text-3xl font-bold">
      {{ !config.pageAlias ? 'Pages' : `${config.pageAlias}s` }}
    </h1>
    <div class="mb-6 flex items-center justify-between gap-4">
      <template v-if="$page.props.auth.user.storage_sub || config.freePages === -1 || (config.freePages > 0 && pages.data.length < config.freePages)">
        <button class="btn-primary ml-auto" @click="isOpen = true">
          Create <span class="hidden md:inline">{{ !config.pageAlias ? 'Page' : `${config.pageAlias}` }}</span>
        </button>
        <DefaultModal
          :open="isOpen"
          :title="!config.pageAlias ? 'Create Page' : `Create ${config.pageAlias}`"
          @close="isOpen = false"
        >
          <NewPage @success="isOpen = false" />
        </DefaultModal>
      </template>
      <Link v-else :href="$routes.subscriptions()" class="btn-primary ml-auto">
        Create <span class="hidden md:inline">{{ !config.pageAlias ? 'Page' : `${config.pageAlias}` }}</span>
      </Link>
    </div>
    <div class="rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead v-if="pages.data.length">
          <tr class="text-left font-bold hidden lg:table-row">
            <th class="px-6 pb-4 pt-6">
              Title
            </th>
            <th v-if="showOwner" class="px-6 pb-4 pt-6">
              Owner
            </th>
            <th v-if="!!config.enablePlaylistDueDate" class="px-6 pb-4 pt-6">
              Due Date
            </th>
            <th class="px-6 pb-4 pt-6" colspan="2">
              Views
            </th>
          </tr>
        </thead>
        <tbody>
          <Page
            v-for="page in pages.data"
            :key="page.id"
            :page
          />
          <tr v-if="pages.data.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No pages found.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <Pagination v-if="pages.meta.last > 1" :meta="pages.meta" />
  </Layout>
</template>

<script setup>
import { Head, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import Page from '../Pages/Page.vue';
import DefaultModal from '../../Shared/DefaultModal.vue';
import NewPage from './New.vue'
import Pagination from '../../Shared/Pagination.vue';
import { computed, ref } from 'vue';
import { config } from '@/siteConfig';

const props = defineProps({
  pages: {
    type: Object,
    required: true,
  },
  filterOptions: {
    type: Array,
    required: true,
  },
  filters: {
    type: Object,
    required: true,
  },
  collaborations: {
    type: Array,
    required: false
  }
})

const isOpen = ref(false);
const filter = ref(props.filterOptions.length ? props.filterOptions.find((person) => person.selected === true).value : [])
const showOwner = computed(() => {
  return !!props.filterOptions.length && filter.value === 'all'
})
</script>
