<template>
  <div>
    <button class="flex items-center px-2 text-primary hover:text-primary-700" title="Assign Representation" @click="isOpen=true">
      <slot />
    </button>
    <DefaultModal
      :open="isOpen"
      :title="`Assign Representation to ${user.name}`"
      @close="isOpen = false"
    >
      <div class="gap-6 flex flex-wrap w-full p-8">
        <div class="flex flex-col w-full">
          <div class="py-2 flex justify-between items-center hover:bg-gray-100 w-full">
            Me
            <button v-if="!user.representation.find((r)=>r.hashid===$page.props.auth.user.hashid)" title="Add Client" @click="addRep($page.props.auth.user)">
              <UserPlusIcon class="size-6 text-primary" />
            </button>
            <button v-else title="Remove Client" @click="removeRep(user.representation.find((r)=>r.hashid===$page.props.auth.user.hashid).team_collaborator_id)">
              <UserMinusIcon class="size-6 text-red-700 hover:text-red-600" />
            </button>
          </div>
          <div v-for="subUser in $page.props.subAccounts" :key="subUser.id" class="py-2 flex justify-between items-center hover:bg-gray-100 w-full">
            {{ subUser.name }}
            <button v-if="!subUser.clients.find((c)=>c.hashid===user.hashid)" title="Add Client" @click="addRep(subUser)">
              <UserPlusIcon class="size-6 text-primary" />
            </button>
            <button v-else title="Remove Client" @click="removeRep(subUser.clients.find((c)=>c.hashid===user.hashid).team_collaborator_id)">
              <UserMinusIcon class="size-6 text-red-700 hover:text-red-600" />
            </button>
          </div>
        </div>
      </div>
    </DefaultModal>
  </div>
</template>

<script setup>
import DefaultModal from '../../Shared/DefaultModal.vue';
import { ref } from 'vue';
import { UserMinusIcon, UserPlusIcon } from '@heroicons/vue/24/outline';
import { router } from '@inertiajs/vue3';
import * as $routes from '../../routes';

const props = defineProps({
  user: Object,
  subAccounts: Array,
})
const isOpen = ref(false)

const addRep = (rep) => {
  router.post(
    $routes.team_collaborators(),
    {
      team_collaborator: {
        team_id: props.user.team.id,
        collaborator_id: rep.id,
        permissions: {
          media: { view: true, manage: false, upload: true},
          playlists: [ { id: "default", view: true, manage: false, upload: true} ]
        }
      }
    }, { preserveScroll: true, preserveState: true}
  )
}

const removeRep = (tc_id) => {
  router.delete(
    $routes.team_collaborator(tc_id),
    { preserveScroll: true, preserveState: true}
  )
}
</script>
