<template>
  <tr
    :key="playlist.id"
    class="focus-within:bg-gray-100 hover:bg-gray-100"
  >
    <td class="border-t">
      <Link
        class="flex flex-col px-6 py-4 focus:text-primary-600 hover:text-primary-600 text-primary"
        :href="$routes.playlist(playlist.hashid)"
      >
        <span class="max-w-56 2xl:max-w-prose overflow-hidden text-ellipsis h-5 leading-5">{{ playlist.title }}</span>
        <div v-if="showOwner" class="inline-block lg:hidden text-sm">
          {{ isMine ? 'Me' : playlist.user.name }}
        </div>
        <DateString v-if="playlist.due_date" :date="playlist.due_date" class="inline-block lg:hidden text-sm" />
        <div class="inline-block lg:hidden text-sm">
          Views: {{ playlist.count_openings }}
        </div>
      </Link>
    </td>
    <td v-if="showOwner" class="hidden lg:table-cell border-t">
      <div class="px-6 py-4">
        {{ isMine ? 'Me' : playlist.user.name }}
      </div>
    </td>
    <td v-if="!!$env.VITE_ENABLE_PLAYLIST_DUE_DATE" class="hidden lg:table-cell border-t">
      <div class="px-6 py-4">
        <DateString v-if="playlist.due_date" :date="playlist.due_date" />
      </div>
    </td>
    <td class="hidden lg:table-cell border-t">
      <div class="px-6 py-4">
        {{ playlist.count_openings }}
      </div>
    </td>
    <td class="w-px border-t">
      <div class="hidden xl:flex flex-row px-2">
        <Link
          v-if="canManage"
          :href="$routes.playlist(playlist.hashid)"
          class="flex items-center px-2 text-primary hover:text-primary-700"
          title="Manage Playlist"
        >
          <PencilIcon class="size-6" />
        </Link>
        <a
          :href="$routes.playlist_view(playlist.hashid)"
          target="_blank"
          class="flex items-center px-2 text-primary hover:text-primary-700"
          title="Preview"
        >
          <EyeIcon class="size-6" />
        </a>
        <NewFromPlaylist v-if="canUpload" :playlist>
          <button
            class="flex items-center px-2 text-primary hover:text-primary-700"
          >
            <CloudArrowUpIcon class="size-6" />
          </button>
        </NewFromPlaylist>

        <button
          class="flex items-center px-2 text-primary hover:text-primary-700"
          title="Copy URL"
          @click="copy(`${$env.VITE_APP_URL}${$routes.playlist_view(playlist.hashid)}`)"
        >
          <ClipboardDocumentCheckIcon class="size-6" />
        </button>
        <QrCode :playlist />
        <Destroy v-if="canManage" :playlist>
          <TrashIcon class="size-6 text-red-700 hover:text-red-600" />
        </Destroy>
      </div>
      <div class="px-2 flex xl:hidden">
        <Popover class="relative">
          <PopoverButton class="text-primary hover:text-primary-700">
            <EllipsisVerticalIcon class="size-6" />
          </PopoverButton>

          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel
              class="absolute right-0 z-50 bg-white rounded shadow p-2 flex flex-row gap-2"
            >
              <Link
                v-if="canManage"
                :href="$routes.playlist(playlist.hashid)"
                class="flex items-center px-2"
                title="Manage Playlist"
              >
                <PencilIcon class="size-6" />
              </Link>
              <a
                :href="$routes.playlist(playlist.hashid, {preview: true})"
                target="_blank"
                class="flex items-center px-2"
                title="Preview"
              >
                <EyeIcon class="size-6" />
              </a>
              <NewFromPlaylist v-if="canUpload" :playlist>
                <button
                  class="flex items-center px-2"
                >
                  <CloudArrowUpIcon class="size-6" />
                </button>
              </NewFromPlaylist>

              <button
                class="flex items-center px-2"
                title="Copy URL"
                @click="copy(`${$env.VITE_APP_URL}${$routes.playlist_view(playlist.hashid)}`)"
              >
                <ClipboardDocumentCheckIcon class="size-6" />
              </button>
              <QrCode :playlist />
              <Destroy v-if="canManage" :playlist>
                <TrashIcon class="size-6 text-red-700" />
              </Destroy>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </td>
  </tr>
</template>
<script setup>
import QrCode from './QrCode.vue';
import {
  ClipboardDocumentCheckIcon,
  TrashIcon,
  CloudArrowUpIcon,
  EyeIcon,
  PencilIcon,
  EllipsisVerticalIcon,
} from '@heroicons/vue/24/outline';
import DateString from '@/Shared/DateString.vue';
import { Link, usePage } from '@inertiajs/vue3';
import Destroy from './Destroy.vue';
import NewFromPlaylist from '../PlaylistUploads/NewFromPlaylist.vue';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';

const props = defineProps({
  playlist: Object,
  showOwner: Boolean,
  permissions: Array,
})

const copy = (text) => {
  navigator.clipboard.writeText(text)
  usePage().props.flash.success = `URL ${text} copied to clipboard`
}
const isMine = props.playlist.user.hashid === usePage().props.auth.user.hashid
const canManage = isMine || (props.permissions?.find((p) => p.id === props.playlist.hashid) ? props.permissions?.find((p) => p.id === props.playlist.hashid)?.manage  : props.permissions?.find((p) => p.id === 'default')?.manage)
const canUpload = isMine || (props.permissions?.find((p) => p.id === props.playlist.hashid) ? props.permissions?.find((p) => p.id === props.playlist.hashid)?.upload  : props.permissions?.find((p) => p.id === 'default')?.upload)
</script>
