<template>
  <Layout>
    <Head :title="`${page.name}`" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="!config.pageAlias ? $routes.pages() : $routes.tributes()"
      >
        {{ !config.pageAlias ? 'Pages' : `${config.pageAlias}s` }}
      </Link>
      <span class="font-medium text-primary-400"> / </span>
      {{ page.name }}
    </h1>

    <PageMenu :page />
    <div class="flex flex-wrap w-full items-start justify-between gap-6">
      <div v-if="canManage" class="max-w-3xl rounded bg-white shadow sm:min-w-96 w-full sm:w-auto">
        <PageForm
          v-model="form"
          @submit="form.put($routes.page(page.hashid))"
        >
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <Destroy
              v-if="!page.deleted_at"
              :page
            >
              <span class="text-red-700 hover:underline">
                Delete <span class="hidden md:inline">Page</span>
              </span>
            </Destroy>
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Update Page
            </LoadingButton>
          </div>
        </PageForm>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue'
import * as $routes from '@/routes';
import PageForm from '../Pages/Form.vue';
import omit from 'lodash/omit';
import LoadingButton from '../../Shared/LoadingButton.vue';
import Destroy from './Destroy.vue';
import PageMenu from './Components/PageMenu.vue';
import { config } from '@/siteConfig';

const props = defineProps({
  page: {
    type: Object,
    required: true,
  }
})

const form = useForm({
  page: {
    ...omit(props.page, 'id', 'deleted_at'),
    timezone: props.page.timezone || Intl.DateTimeFormat().resolvedOptions().timeZone,
  }
})

const canManage = usePage().props.auth.user.hashid === props.page.user.hashid || props.page.permissions?.manage

</script>
