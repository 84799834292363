<template>
  <Layout>
    <div>
      <Head title="Create User" />
      <h1 class="mb-8 text-3xl font-bold">
        <Link
          class="text-primary-500 hover:text-primary-800"
          :href="$routes.company()"
        >
          {{ $page.props.auth.user.company.name }}
        </Link>
        <span class="font-medium text-amber-400"> / </span> New User
      </h1>
      <div class="max-w-3xl overflow-hidden rounded bg-white shadow">
        <UserForm v-model="form" @submit="form.post($routes.company_user())">
          <div
            class="flex items-center justify-end border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <loading-button
              :loading="form.processing"
              class="btn-primary"
              type="submit"
            >
              Create User
            </loading-button>
          </div>
        </UserForm>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head, Link, useForm, usePage } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import LoadingButton from '@/Shared/LoadingButton.vue';
import UserForm from './Form.vue';
import * as $routes from '../../routes';

const form = useForm({
  user: {
    first_name: '',
    last_name: '',
    email: '',
    role: usePage().props.auth.user.role,
  },
})
</script>
