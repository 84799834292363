<template>
  <Layout>
    <Head title="Clients" />
    <h1 class="mb-8 text-3xl font-bold">
      <Link
        class="text-primary-500 hover:text-primary-800"
        :href="$routes.company()"
      >
        {{ $page.props.auth.user.company.name }}
      </Link>
      <span class="font-medium text-primary-400"> / </span>
      Clients
    </h1>

    <CompanyMenu />
    <div class="mb-6 flex items-center justify-between">
      <button class="ml-auto btn-primary" @click="modalNew = true">
        Invite <span class="hidden md:inline">Client</span>
      </button>

      <DefaultModal
        :open="modalNew"
        title="Invite Client"
        @close="modalNew = false"
      >
        <NewInvitation @success="modalNew = false" />
      </DefaultModal>
    </div>
    <div v-if="toAccept.length > 0 || clients.length > 0 || invitations.length > 0" class="overflow-x-auto rounded bg-white shadow">
      <table class="w-full whitespace-nowrap">
        <thead>
          <tr class="text-left font-bold">
            <th class="px-6 pb-4 pt-6">
              Client
            </th>
            <th class="px-6 pb-4 pt-6" colspan="2">
              Representation
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="invite in invitations"
            :key="invite.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="px-6 py-4"
              >
                <div>{{ invite.first_name }} {{ invite.last_name }}</div>
                <div class="text-xs">
                  {{ invite.email }} <span class="italic text-tertiary">
                    Invitation
                    <template v-if="invite.created_at !== invite.updated_at">re-</template>sent <DateString :date="invite.updated_at" /></span>
                </div>
              </div>
            </td>
            <td class="w-px border-t">
              <div class="flex flex-row px-2">
                <button class="relative mx-2" title="Resend Invitation" @click="resend(invite.code)">
                  <EnvelopeIcon class="size-6 text-primary" />
                  <ArrowPathIcon class="size-4 absolute bottom-0 right-0 bg-green-100 rounded-full p-0.5" />
                </button>
                <DestroyInvitation :invitation="invite">
                  <TrashIcon
                    class="size-6 text-red-700 hover:text-red-600"
                  />
                </DestroyInvitation>
              </div>
            </td>
          </tr>
          <tr
            v-for="request in toAccept"
            :key="request.id"
            class="focus-within:bg-gray-100 hover:bg-gray-100"
          >
            <td class="border-t">
              <div
                class="px-6 py-4"
              >
                {{ request.user.first_name }} {{ request.user.last_name }}
                <span class="text-xs">would like to connect</span>
              </div>
            </td>
            <td class="w-px border-t" colspan="3">
              <div class="flex flex-row items-center justify-end px-2">
                <Link
                  :href="`/invitations/${request.code}/accept`"
                  class="text-xs p-1.5 mr-2 bg-primary-600 rounded text-white"
                >
                  Accept
                </Link>
                <DestroyInvitation :invitation="request">
                  <TrashIcon
                    class="size-6 text-red-700"
                  />
                </DestroyInvitation>
              </div>
            </td>
          </tr>
          <Client
            v-for="collaboration in clients"
            :key="collaboration.id"
            :client="collaboration"
          />
          <tr v-if="clients.length === 0 && toAccept.length === 0 && invitations.length === 0">
            <td class="border-t px-6 py-4" colspan="4">
              No clients.
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </Layout>
</template>

<script setup>
import Layout from "@/Layouts/Main.vue"
import { Head, Link, router } from '@inertiajs/vue3';
import { ref } from 'vue';
import DefaultModal from '@/Shared/DefaultModal.vue';
import NewInvitation from '@/Pages/Collaboration/New.vue';
import {
  ArrowPathIcon,
  EnvelopeIcon,
  TrashIcon,
} from '@heroicons/vue/24/outline';
import DestroyInvitation from '../Invitations/Destroy.vue';
import * as $routes from '@/routes';
import CompanyMenu from '../Company/Components/CompanyMenu.vue';
import Client from './Client.vue';
import DateString from '../../Shared/DateString.vue';

defineProps({
  invitations: {
    type: Array,
    required: true,
  },
  toAccept: {
    type: Array,
    required: true,
  },
  clients: {
    type: Object,
    required: true,
  }
})

const modalNew = ref(false);

const resend = (code) => {
  router.visit($routes.invitation_resend_notifications(code))
}
</script>
