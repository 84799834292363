<template>
  <Layout>
    <Head title="Storage Plan" />
    <div class="flex min-h-screen items-center justify-center bg-brand p-6">
      <div class="w-full max-w-screen-xl">
        <Logo class="mx-auto block w-full max-w-xs h-24" />
        <div
          class="mt-8 overflow-hidden rounded-lg bg-white shadow-xl"
        >
          <div class="px-5 py-6 md:px-10 md:py-12">
            <FlashMessages />
            <section>
              <div class="mx-auto max-w-screen-xl lg:px-6">
                <div class="mx-auto max-w-screen-md text-center">
                  <template v-if="$env.VITE_APP_ID==='hff'">
                    <h2 class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900">
                      Create and share group video tributes
                    </h2>
                    <p class="font-light text-gray-500 text-lg sm:text-xl">
                      We offer a variety of plans to best meet your needs, whether you're looking to create a single tribute or collaborate with your entire corporate team.
                    </p>
                  </template>
                  <template v-if="$env.VITE_APP_ID==='rt' || $env.VITE_APP_ID==='esr' ">
                    <h2 v-if="selectedPlan?.trial_days > 0" class="mb-4 text-4xl tracking-tight font-extrabold text-gray-900 ">
                      {{ selectedPlan?.trial_days }}-day free trial offer!
                    </h2>
                    <p class="font-light text-gray-500 sm:text-xl">
                      {{ selectedPlan?.name }}                               <DollarAmount :amount="frequency === 'monthly' ? selectedPlan?.monthly_price : selectedPlan?.annual_price / 12" :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}" />
                      <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan?.monthly_price - Math.floor(selectedPlan?.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan?.annual_price / 12) - Math.floor(selectedPlan?.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                        />
                      </span>
                      <span class="text-gray-500">/month</span>
                    </p>
                    <div v-if="frequency === 'annual'" class="text-gray-500 text-xs mt-2">
                      billed annually
                    </div>
                  </template>
                </div>
                <div class="flex justify-center py-4">
                  <Toggle
                    v-model="frequency" :classes="{
                      container: 'inline-block rounded-full outline-none focus:ring focus:ring-primary-700 focus:ring-opacity-30',
                      toggle: 'flex w-28 h-8 rounded-full relative cursor-pointer transition items-center box-content border-2 text-xs leading-none font-semibold',
                      toggleOn: 'bg-primary-700 border-primary-700 justify-start text-white',
                      toggleOff: 'bg-primary-700 border-primary-700 justify-end text-white',
                      toggleOnDisabled: 'bg-gray-300 border-gray-300 justify-start text-gray-400 cursor-not-allowed',
                      toggleOffDisabled: 'bg-gray-200 border-gray-200 justify-end text-gray-400 cursor-not-allowed',
                      handle: 'inline-block bg-white w-8 h-8 top-0 rounded-full absolute transition-all',
                      handleOn: 'left-full transform -translate-x-full',
                      handleOff: 'left-0',
                      handleOnDisabled: 'bg-gray-100 left-full transform -translate-x-full',
                      handleOffDisabled: 'bg-gray-100 left-0',
                      label: 'text-center w-fit border-box whitespace-nowrap select-none pl-6 pr-4',
                    }"
                    true-value="annual"
                    false-value="monthly"
                    on-label="Yearly"
                    off-label="Monthly"
                    @change="(v) => changeFrequency(v)"
                  />
                </div>
                <template v-if="$env.VITE_APP_ID==='hff'">
                  <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2 mb-4 xl:hidden">
                    <div
                      class="flex flex-col text-xs text-left border border-black p-1 rounded"
                      :class="!selectedPlan ? 'bg-secondary-50 border-secondary-700' : 'bg-white hover:border-secondary-500 cursor-pointer'"
                      @click="selectedPlan = null"
                    >
                      <div class="text-sm font-semibold">
                        Free
                      </div>
                      <div>5 Minutes</div>
                    </div>
                    <div
                      v-for="plan in $page.props.plans"
                      :key="plan.id"
                      class="flex flex-col text-xs text-left border border-black p-1 rounded cursor-pointer"
                      :class="selectedPlan?.id === plan.id ? 'bg-secondary-50 border-secondary-700' : 'bg-white hover:border-secondary-500'"
                      @click="changePlan(plan.id)"
                    >
                      <div class="text-sm font-semibold flex justify-between">
                        {{ plan.name }}
                        <div>
                          <DollarAmount :amount="frequency === 'monthly' ? plan.monthly_price : plan.annual_price / 12" :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}" />
                          <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                            <DollarAmount
                              :amount="frequency === 'monthly' ?
                                plan.monthly_price - Math.floor(plan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                                (plan.annual_price/12) - Math.floor(plan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                            />
                          </span>
                          <span class="text-xs">/month</span>
                        </div>
                      </div>
                      <div class="flex justify-between">
                        {{ plan.storage_minutes }} Minutes
                        <div v-if="frequency === 'annual'" class="text-xs">
                          billed annually
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full xl:hidden">
                    <div class="mx-auto w-full">
                      <Disclosure v-slot="{ open }">
                        <DisclosureButton
                          class="flex w-full justify-between rounded bg-primary-100 px-4 py-2 text-left text-sm hover:bg-primary-200 focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75"
                        >
                          <span>Compare Plans</span>
                          <ChevronUpIcon
                            :class="open ? 'rotate-180 transform' : ''"
                            class="h-5 w-5"
                          />
                        </DisclosureButton>
                        <DisclosurePanel class="py-2 text-sm bg-gray-50 rounded-b px-1">
                          All plans include:
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Invite unlimited contributors</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Rearrange clips</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Share with link or QR code</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Page view analytics</span>
                          </li>
                          <table class="w-full">
                            <thead>
                              <tr>
                                <th />
                                <th>Free</th>
                                <th
                                  v-for="plan in $page.props.plans"
                                  :key="plan.id"
                                >
                                  {{ plan.name }}
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr class="border-t">
                                <td>Storage Minutes</td>
                                <td class="text-center">
                                  5
                                </td>
                                <td
                                  v-for="plan in $page.props.plans"
                                  :key="plan.id"
                                  class="text-center"
                                >
                                  {{ plan.storage_minutes }}
                                </td>
                              </tr>
                              <tr class="border-t">
                                <td>Playlists</td>
                                <td class="text-center">
                                  1
                                </td>
                                <td
                                  v-for="plan in $page.props.plans"
                                  :key="plan.id"
                                  class="text-center"
                                >
                                  {{ plan.playlists === -1 ? '∞' : plan.playlists }}
                                </td>
                              </tr>
                              <tr class="border-t">
                                <td>Collaborators</td>
                                <td class="text-center">
                                  0
                                </td>
                                <td
                                  v-for="plan in $page.props.plans"
                                  :key="plan.id"
                                  class="text-center"
                                >
                                  {{ plan.collaborators }}
                                </td>
                              </tr>
                              <tr class="border-t">
                                <td>Remove Ads</td>
                                <td class="text-center">
                                  <XMarkIcon class="size-5 text-red-700 mx-auto" />
                                </td>
                                <td
                                  v-for="plan in $page.props.plans"
                                  :key="plan.id"
                                  class="text-center"
                                >
                                  <CheckIcon v-if="!plan.ad_supported" class="size-5 text-green-500 mx-auto" />
                                  <XMarkIcon v-else class="size-5 text-red-700 mx-auto" />
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </DisclosurePanel>
                      </Disclosure>
                    </div>
                  </div>
                  <div class="hidden xl:grid grid-cols-4 gap-4 ">
                    <div
                      class="flex flex-col p-4 mx-auto max-w-lg text-center rounded-lg xl:p-6 border border-black"
                      :class="!selectedPlan ? 'bg-secondary-50 border-secondary-700' : 'bg-white cursor-pointer hover:border-secondary-500'"
                      @click="selectedPlan = null"
                    >
                      <h3 class="mb-4 text-2xl font-semibold">
                        Free
                      </h3>
                      <p class="font-light text-gray-500 sm:text-lg">
                        No need for a commitment or a credit card.
                      </p>
                      <div class="h-36" />
                      <ul role="list" class="mb-8 space-y-4 text-left">
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span><span class="font-semibold">5</span> minutes of storage</span>
                        </li>
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span>Invite unlimited contributors</span>
                        </li>
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span>Rearrange clips</span>
                        </li>
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span>Share with link or QR code</span>
                        </li>
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span>Page view analytics</span>
                        </li>
                        <li class="flex items-center space-x-3">
                          <CheckIcon class="size-5 text-green-500" />
                          <span>Playlists: <span class="font-semibold">1</span></span>
                        </li>
                      </ul>
                    </div>



                    <template
                      v-for="plan in $page.props.plans"
                      :key="plan.id"
                    >
                      <div
                        class="flex flex-col p-4 mx-auto max-w-lg text-center rounded-lg border border-black xl:p-6"
                        :class="selectedPlan?.id === plan.id ? 'bg-secondary-50 border-secondary-700' : 'bg-white cursor-pointer hover:border-secondary-500'"
                        @click="changePlan(plan.id)"
                      >
                        <h3 class="mb-4 text-2xl font-semibold">
                          {{ plan.name }}
                        </h3>
                        <p class="font-light text-gray-500 sm:text-lg">
                          {{ plan.description }}
                        </p>
                        <div class="flex flex-col my-8">
                          <div class="flex justify-center items-baseline">
                            <span class=" text-5xl font-extrabold">
                              <DollarAmount :amount="frequency === 'monthly' ? plan.monthly_price : plan.annual_price / 12" :class="{'line-through text-3xl': !!$page.props.customer?.discount?.coupon}" />
                              <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                                <DollarAmount
                                  :amount="frequency === 'monthly' ?
                                    plan.monthly_price - Math.floor(plan.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                                    (plan.annual_price/12) - Math.floor(plan.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 / 12"
                                />
                              </span>
                            </span>
                            <span class="text-gray-500">/month</span>
                          </div>
                          <div v-if="frequency === 'annual'" class="text-gray-500 text-xs mt-2">
                            billed annually
                          </div>
                        </div>
                        <ul role="list" class="space-y-4 text-left">
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span><span class="font-semibold">{{ plan.storage_minutes }}</span> minutes of storage</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Invite unlimited contributors</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Rearrange clips</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Share with link or QR code</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Page view analytics</span>
                          </li>
                          <li class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span v-if="plan.playlists !== -1">Playlists: <span class="font-semibold">{{ plan.playlists }}</span></span>
                            <span v-else>Playlists: <span class="font-semibold">Unlimited</span></span>
                          </li>
                          <li v-if="!!plan.collaborators" class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Collaborators: <span class="font-semibold">{{ plan.collaborators }}</span></span>
                          </li>
                          <li v-if="!plan.ad_supported" class="flex items-center space-x-3">
                            <CheckIcon class="size-5 text-green-500" />
                            <span>Remove Advertising</span>
                          </li>
                        </ul>
                      </div>
                    </template>
                  </div>
                </template>
                <template v-if="$env.VITE_APP_ID==='rt' || $env.VITE_APP_ID==='esr'">
                  <ul class="mb-5 text-lg font-medium text-gray-900 list-disc">
                    <template v-if="!!$page.props.auth.user.business">
                      <li>Includes {{ selectedPlan?.storage_minutes }} minutes of cloud storage</li>
                      <li>Add unlimited sub-accounts and allocate storage as needed</li>
                      <li>Access all client media in one easy-to-use system</li>
                    </template>
                    <template v-else>
                      <li>
                        Upload auditions, clips and reels
                      </li>
                      <li>
                        Link media library with Agents and Managers
                      </li>
                    </template>
                    <li>
                      Create unlimited custom playlists that include analytics
                    </li>
                    <li>
                      Easily share media with downloadable links
                    </li>
                    <li>
                      Generate QR codes for headshots &amp; websites
                    </li>
                    <li>
                      Access to priority customer support and more!
                    </li>
                  </ul>
                  <p v-if="!$page.props.auth.user.business">
                    Get {{ selectedPlan?.trial_days }} days free and a coupon for 20% off reel editing!
                  </p>
                  <p v-else>
                    Your {{ selectedPlan?.trial_days }}-day free trial includes an onboarding session and a dedicated media management specialist. Get started today!
                  </p>
                </template>
              </div>
            </section>

            <div v-show="!!selectedPlan" class="flex flex-col md:flex-row gap-5">
              <div class="w-full max-w-prose mx-auto">
                <div class="flex w-full flex-col mt-4 gap-5 rounded bg-white shadow p-5">
                  <Promo />
                  <Payment
                    ref="paymentComponent"
                    :client-secret="$page.props.setupIntent"
                    @success="(setupIntent) => intentConfirmed(setupIntent)"
                    @failure="(error) => handleError(error)"
                  />
                  <div v-if="errors" class="flex justify-between items-center">
                    <div class="text-xs font-medium text-red-700 mr-12">
                      {{ errors.message }}
                    </div>
                  </div>
                  <div v-if="$page.props.errors" class="flex justify-between items-center">
                    <div v-for="(error, index) in $page.props.errors?.pay" :key="index" class="text-xs font-medium text-red-700 mr-12">
                      {{ error }}
                    </div>
                  </div>
                  <div class="flex">
                    <Link :href="$routes.onboard_setup()" class="btn-secondary">
                      No Thanks
                    </Link>
                    <LoadingButton
                      :loading="cardProcessing || form.processing"
                      class="btn-primary ml-auto"
                      type="submit"
                      @click="submit"
                    >
                      {{ selectedPlan?.trial_days > 0 ? 'Start Free Trial' : 'Subscribe' }}
                    </LoadingButton>
                  </div>

                  <div class="text-xs">
                    {{ selectedPlan?.name }} is a recurring subscription service offered by {{ $env.VITE_APP_NAME }}.
                    <template v-if="selectedPlan?.trial_days > 0">
                      Following your {{ selectedPlan?.trial_days }}-day free trial period,
                      your card will be automatically charged
                      <span :class="{'line-through': !!$page.props.customer?.discount?.coupon}">
                        <DollarAmount :amount="frequency === 'monthly' ? selectedPlan?.monthly_price : selectedPlan?.annual_price" />
                      </span>
                      <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan?.monthly_price - Math.floor(selectedPlan?.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan?.annual_price/12) - Math.floor(selectedPlan?.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100"
                        />
                      </span>
                      each {{ frequency === 'monthly' ? 'month' : 'year' }}
                      <span v-if="!!$page.props.customer?.discount?.coupon">
                        until <DateString :date="$page.props.customer?.discount?.end * 1000" /> when you will revert to the non-promotional price at
                        <DollarAmount :amount="frequency === 'monthly' ? selectedPlan?.monthly_price : selectedPlan?.annual_price" />
                        each {{ frequency === 'monthly' ? 'month' : 'year' }}
                      </span>
                      according to your selected plan.
                    </template>
                    <template v-else>
                      Your card will be charged
                      <span :class="{'line-through': !!$page.props.customer?.discount?.coupon}"><DollarAmount :amount="selectedPlan?.monthly_price" /></span>
                      <span v-if="!!$page.props.customer?.discount?.coupon" class="ml-2 text-green-700">
                        <DollarAmount
                          :amount="frequency === 'monthly' ?
                            selectedPlan?.monthly_price - Math.floor(selectedPlan?.monthly_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100 :
                            (selectedPlan?.annual_price/12) - Math.floor(selectedPlan?.annual_price * ($page.props.customer?.discount?.coupon?.percent_off / 100) * 100) / 100"
                        />
                        today, and automatically each {{ frequency === 'monthly' ? 'month' : 'year' }}
                      </span>
                      according to your selected plan.
                    </template>
                    You can cancel or change your plan anytime in your account settings. For more details, see our
                    <a class="text-secondary hover:text-secondary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/terms-and-conditions`" target="_blank">Terms of Service</a> and
                    <a class="text-secondary hover:text-secondary-600" :href="`${ $env.VITE_BROCHUREWARE_URL }/privacy-policy`" target="_blank">Privacy Policy</a>.
                  </div>
                </div>
              </div>
            </div>
            <div v-show="!selectedPlan" class="w-full flex">
              <Link :href="$routes.onboard_setup()" class="btn-secondary mx-auto mt-4">
                Proceed with Free Account
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import Layout from '@/Layouts/Minimal.vue';
import { Head, useForm, usePage, Link } from '@inertiajs/vue3';
import * as $routes from '@/routes';
import { CheckIcon, ChevronUpIcon, XMarkIcon } from '@heroicons/vue/24/outline';
import Toggle from '@vueform/toggle'
import { ref } from 'vue';
import DollarAmount from '../../Shared/DollarAmount.vue';
import Payment from '../../Shared/SetupIntent.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import Promo from '../Subscriptions/Promo.vue';
import DateString from '../../Shared/DateString.vue';
import Logo from '../../Shared/Logo.vue';
import FlashMessages from '../../Shared/FlashMessages.vue';
import { Disclosure, DisclosureButton, DisclosurePanel } from '@headlessui/vue';


const paymentComponent = ref()
const cardProcessing = ref(false)
const errors = ref(null)
const frequency = ref('annual')
const selectedPlan = ref(usePage().props.currentPlan)
const planPriceId = ref(selectedPlan.value?.annual_stripe_price_id)

const form = useForm({
  plan: planPriceId.value,
  pm_id: null,
  stripe_pm_id: null
})

const intentConfirmed = (setupIntent) => {
  form.stripe_pm_id = setupIntent.payment_method
  form.post($routes.onboard(),
    {
      preserveScroll: true,
      onError: () => { cardProcessing.value = false }
    }
  )
}

const submit = () => {
  cardProcessing.value=true
  paymentComponent.value.createPaymentMethod()
}
const changePlan = (planId) => {
  selectedPlan.value = usePage().props.plans.find(p=>p.id === planId)
  updatePlanPriceId()
}

const changeFrequency = (freq) => {
  frequency.value = freq
  updatePlanPriceId()
}

const updatePlanPriceId = () => {
  form.plan = frequency.value === 'monthly' ? selectedPlan.value?.monthly_stripe_price_id : selectedPlan.value?.annual_stripe_price_id
}

const handleError = (error) => {
  cardProcessing.value = false
  errors.value = error
}
</script>
