<template>
  <Layout>
    <Head :title="$page.props.auth.user.company.name" />
    <div class="mb-8 flex max-w-3xl justify-start">
      <h1 class="text-3xl font-bold">
        {{ $page.props.auth.user.company.name }}
      </h1>
    </div>

    <CompanyMenu />

    <div class="flex flex-wrap gap-4 justify-between">
      <div class="max-w-3xl overflow-hidden rounded bg-white shadow w-full md:w-auto">
        <CompanyForm v-model="form" @submit="submit">
          <div
            class="flex items-center border-t border-gray-200 bg-gray-100 px-8 py-4"
          >
            <LoadingButton
              :loading="form.processing"
              class="btn-primary ml-auto"
              type="submit"
            >
              Update Company
            </LoadingButton>
          </div>
        </CompanyForm>
      </div>
    </div>

    <template v-if="$page.props.auth.user.storage_sub">
      <h2 class="my-8 text-2xl font-bold">
        Users
      </h2>
      <div class="mb-6 flex items-center justify-between">
        <SearchFilter
          v-model="form.search"
          class="mr-4 w-full max-w-md"
          @reset="reset"
        />
        <Link
          v-if="can.create_user"
          class="btn-primary"
          :href="$routes.new_user()"
        >
          Create <span class="hidden md:inline">User</span>
        </Link>
      </div>
      <div class="overflow-x-auto rounded bg-white shadow">
        <table class="w-full whitespace-nowrap">
          <thead>
            <tr class="text-left font-bold">
              <th class="px-6 pb-4 pt-6">
                Name
              </th>
              <th v-if="config.enableClients" class="px-6 pb-4 pt-6" colspan="2">
                Clients
              </th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="user in users"
              :key="user.id"
              class="focus-within:bg-gray-100 hover:bg-gray-100"
            >
              <td class="border-t">
                <Link
                  class="flex flex-col px-6 py-4 focus:text-amber-500"
                  :href="$routes.edit_user(user.hashid)"
                  aria-label="Edit"
                >
                  <img
                    v-if="user.photo"
                    class="-my-2 mr-2 block h-5 w-5 rounded-full"
                    :src="user.photo"
                    alt="Photo"
                  >
                  <div>{{ user.name }}</div>
                  <div class="text-xs">
                    {{ user.email }}
                  </div>
                </Link>
              </td>
              <td v-if="config.enableClients" class="border-t">
                <div
                  class="flex flex-wrap px-6 py-4 focus:text-primary gap-1 text-sm "
                >
                  <template v-for="client in user.clients" :key="client.id">
                    <span
                      class="after:content-[','] last:after:content-['']"
                    >
                      {{ client.name }}
                    </span>
                  </template>
                </div>
              </td>
              <td class="w-px border-t">
                <div class="flex px-2">
                  <AssignClients v-if="config.enableClients" :user="user">
                    <UsersIcon name="Representation" class="size-6" />
                  </AssignClients>
                  <StorageAllocation :user="user" :allocated="user.sponsorship">
                    <CircleStackIcon name="Storage" class="size-6" />
                  </StorageAllocation>
                  <DestroyUser :user>
                    <TrashIcon
                      class="size-6 text-red-700 hover:text-red-600"
                    />
                  </DestroyUser>
                </div>
              </td>
              <td class="w-px border-t" />
            </tr>
            <tr v-if="users.length === 0">
              <td class="border-t px-6 py-4" colspan="4">
                No users found.
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </template>
    <template v-else>
      <div class="mx-auto max-w-screen-md">
        <h2 class="my-4 text-4xl tracking-tight font-extrabold">
          Your whole team in one place
        </h2>
        <p class="mb-8 font-light text-gray-700 sm:text-xl">
          With Rep Pro, you can create sub-accounts, assign clients, and allocate storage.
        </p>
        <div class="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4 mb-4">
          <a class="btn-primary" :href="$routes.subscriptions()">Subscribe to add sub-accounts</a>
        </div>
      </div>
    </template>
  </Layout>
</template>

<script setup>
import { Head, useForm, usePage, Link } from '@inertiajs/vue3';
import Layout from '@/Layouts/Main.vue';
import CompanyForm from './Form.vue';
import LoadingButton from '../../Shared/LoadingButton.vue';
import * as $routes from '../../routes';
import SearchFilter from '../../Shared/SearchFilter.vue';
import CompanyMenu from './Components/CompanyMenu.vue';
import AssignClients from '../Collaboration/AssignClients.vue';
import { CircleStackIcon, TrashIcon, UsersIcon } from '@heroicons/vue/24/outline';
import DestroyUser from './DestroyUser.vue';
import { config } from '@/siteConfig';
import StorageAllocation from '../Collaboration/StorageAllocation.vue';

const form = useForm({
  company: {
    ...usePage().props.auth.user.company,
  },
})

const submit = () => {
  form.put($routes.company(), {})
}
defineProps({
  users: Array,
  clients: Array,
})

const can = { create_user: true}

const reset = () => {}
</script>
