<template>
  <Layout class="max-w-screen-full min-h-screen m-auto py-10" :style="`background-color: ${page.customizations.background_color}`">
    <Head :title="page.name" />
    <div class="max-w-screen-xl mx-auto">
      <h1 class="mb-8 text-3xl font-bold text-center" :style="`color: ${makeColorReadable(tinycolor(page.customizations.background_color).spin(180),page.customizations.background_color)}`">
        {{ page.name }}
      </h1>

      <template v-if="!!gallery.gallery_uploads?.length && !!playlist.playlist_uploads?.length">
        <div class="flex justify-center mb-4">
          <button
            class="whitespace-nowrap rounded-theme-l px-6 py-3 text-sm font-bold text-white" title="Videos"
            :class="display === 'video' ? 'bg-primary-600 cursor-default' : 'bg-primary-500 hover:bg-primary-700'"
            @click="display = 'video'"
          >
            Videos
          </button>
          <button
            class="whitespace-nowrap rounded-theme-r px-6 py-3 text-sm font-bold text-white " href="#" title="Photos"
            :class="display === 'photo' ? 'bg-primary-600 cursor-default' : 'bg-primary-500 hover:bg-primary-700'"
            @click="display= 'photo'"
          >
            Photos
          </button>
        </div>
      </template>

      <div v-if="!!playlist.playlist_uploads?.length && display === 'video'">
        <VideoPlayer
          :class="['w-full','h-[75vh]','video-player', 'vjs-big-play-centered', { loading: !state }]"
          :sources="mediaConfig.sources"
          :poster="mediaConfig.poster"
          :tracks="mediaConfig.tracks"
          :autoplay="config.autoplay"
          :loop="config.loop"
          crossorigin="anonymous"
          playsinline
          :height="config.height"
          :volume="config.volume"
          :controls="true"
          :muted="false"
          @ended="next()"
          @error="e=>handleError(e)"
          @mounted="handleMounted"
        />
        <PlayerPlaylist class="playlist" :playlist-data="playlistData" :index="playMediaIndex" @update:index="handleMediaChange" />
      </div>

      <div v-if="!gallery.gallery_uploads?.length && !playlist.playlist_uploads?.length" class="text-center font-semibold" :style="`color: ${makeColorReadable(tinycolor(playlist.customizations.background_color).spin(180),playlist.customizations.background_color)}`">
        No media yet.
      </div>

      <div v-if="!!gallery.gallery_uploads?.length && display === 'photo'" class="mt-4">
        <VueFlux
          ref="$vueFlux"
          :options="options"
          :rscs="rscs"
          :transitions="transitions"
        >
          <template #preloader="preloaderProps">
            <FluxPreloader v-bind="preloaderProps" />
          </template>

          <template #caption="captionProps">
            <FluxCaption v-bind="captionProps" />
          </template>

          <template #controls="controlsProps">
            <FluxControls v-bind="controlsProps" />
          </template>

          <template #pagination="paginationProps">
            <FluxPagination v-bind="paginationProps" />
          </template>
        </VueFlux>
      </div>
    </div>
  </Layout>
</template>

<script setup>
import { Head } from '@inertiajs/vue3';
import Layout from '@/Layouts/Minimal.vue'
import { computed, nextTick, ref, shallowReactive } from 'vue';
import { VideoPlayer } from '@videojs-player/vue'
import 'video.js/dist/video-js.css'
import {
  VueFlux,
  FluxCaption,
  FluxControls,
  FluxPagination,
  FluxPreloader,
  Img,
  Concentric,
  Zip, Waterfall,
} from 'vue-flux';
import 'vue-flux/style.css';
import PlayerPlaylist from '../../Shared/PlayerList.vue';
import tinycolor from "tinycolor2";

const props = defineProps({
  page: {
    type: Object,
    required: true,
  },
  playlist: {
    type: Object,
    required: true,
  },
  gallery: {
    type: Object,
    required: true,
  }
})

const display = ref(props.playlist.playlist_uploads?.length ? 'video' : 'photo')

const playlistData = props.playlist.playlist_uploads.map( (i) =>  ({
  name: i.upload.name,
  description: i.upload.name,
  duration: i.upload.duration,
  poster: i.upload.preview,
  sources: [
    { src: i.upload.url_playable, type: i.upload.content_type },
  ],
  url_downloadable: i.upload.url_downloadable
}))

const player = ref()
const state = ref()
const config = ref({
  autoplay: true,
  height: '80vh',
  volume: 1,
  playbackRate: 1,
  loop: false,
  preload: 'auto'
})

const playMediaIndex = ref(0)
const mediaConfig = computed(() => ({
  sources: playlistData[playMediaIndex.value]?.sources,
  poster: playMediaIndex.value === 0 ? playlistData[0]?.poster : null,
  tracks: (playlistData[playMediaIndex.value]?.textTracks) ?? []
}))

const handleMounted = (payload) => {
  state.value = payload.state
  player.value = payload.player
  if (playlistData.length > 1) {
    let prevButton = player.value.controlBar.addChild('button', {
      clickHandler: function () {
        prev()
      },
      controlText: 'Previous'
    }, 0)
    prevButton.addClass('vjs-icon-previous-item')
    let nextButton = player.value.controlBar.addChild('button', {
      clickHandler: function () {
        next()
      },
      controlText: 'Next'
    }, 2)
    nextButton.addClass('vjs-icon-next-item')
  }
}

const handleMediaChange = (index) => {
  playMediaIndex.value = index
  nextTick(() => {
    player.value?.play().catch((e)=> {
      handleError(e)
    })
  })
}

const next = () => {
  if (playMediaIndex.value+1 < playlistData.length) {
    handleMediaChange(playMediaIndex.value+1)
  }
}

const prev = () => {
  if (playMediaIndex.value > 0) {
    handleMediaChange(playMediaIndex.value-1)
  }
}

const handleError = (e) => {
  console.log('An error occurred', e)
}

const makeColorReadable = (color, background) => {
  const c = tinycolor(color);
  const bg = tinycolor(background);
  const isBackgroundDarker = tinycolor.readability(bg, 'white') > tinycolor.readability(bg, 'black');
  while (tinycolor.readability(bg, c) < 4.5 && c.getLuminance() >= 0 && c.getLuminance() <= 1)
  {
    if (isBackgroundDarker) {
      c.lighten(5);
    } else {
      c.darken(5);
    }
  }
  return c.toHexString();
};

const $vueFlux = ref();

const options = shallowReactive({
  autoplay: true,
});

const rscs = shallowReactive(props.gallery.gallery_uploads.map((u) => { return new Img(u.upload.url_downloadable, u.upload.name, 'fit', '#000') }));

const transitions = shallowReactive([Waterfall, Zip, Concentric]);
</script>
