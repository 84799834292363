<template>
  <PageForm v-model="form" @submit="submit">
    <div
      class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
    >
      <LoadingButton
        :loading="form.processing"
        class="btn-primary"
        type="submit"
      >
        Create Page
      </LoadingButton>
    </div>
  </PageForm>
</template>

<script setup>
import LoadingButton from '@/Shared/LoadingButton.vue';
import PageForm from './Form.vue';
import { useForm } from '@inertiajs/vue3'
import * as $routes from '@/routes';
import { config } from '@/siteConfig'
const emit = defineEmits(['success']);

const form = useForm({
  page: {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    email_content: config.enablePlaylistContributors ? "Please help me create a group video tribute by contributing a recorded greeting or sharing a memory." :  '',
    customizations: { background_color: '#e2e8f0'}
  }
})

const submit = () => {
  form.post($routes.pages(), {
    onSuccess: () => {
      emit('success');
      form.reset('page');
    },
  });
}
</script>
