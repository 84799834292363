<template>
  <GalleryForm v-model="form" @submit="submit">
    <div
      class="flex items-center justify-end border-t rounded-b border-gray-200 bg-gray-100 px-8 py-4"
    >
      <LoadingButton
        :loading="form.processing"
        class="btn-primary"
        type="submit"
      >
        Create Gallery
      </LoadingButton>
    </div>
  </GalleryForm>
</template>

<script setup>
import LoadingButton from '@/Shared/LoadingButton.vue';
import GalleryForm from './Form.vue';
import { useForm } from '@inertiajs/vue3'
import * as $routes from '@/routes';

const emit = defineEmits(['success']);

const form = useForm({
  gallery: { }
})

const submit = () => {
  form.post($routes.galleries(), {
    onSuccess: () => {
      emit('success');
      form.reset('gallery');
    },
  });
}
</script>
