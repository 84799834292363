<template>
  <div class="absolute right-10 z-50">
    <div
      v-if="$page.props.flash.success"
      class="mb-8 flex max-w-3xl items-center justify-between rounded-theme bg-green-500"
    >
      <div class="flex items-center">
        <CheckIcon class="size-5 text-white ml-4 mr-2 flex-shrink-0" />
        <div class="py-4 text-sm font-medium text-white">
          {{ $page.props.flash.success }}
        </div>
      </div>
      <button type="button" class="group mr-2 p-2" @click="clearFlash()">
        <XMarkIcon class="size-4 block text-green-800 group-hover:text-green-700" />
      </button>
    </div>
    <div
      v-else-if="$page.props.flash.alert"
      class="mb-8 flex max-w-3xl items-center justify-between rounded bg-red-400"
    >
      <div class="flex items-center">
        <XCircleIcon class="size-5 text-white ml-4 mr-2 flex-shrink-0" />
        <div class="py-4 text-sm font-medium text-white">
          {{ $page.props.flash.alert }}
        </div>
      </div>
      <button type="button" class="group mr-2 p-2" @click="clearFlash()">
        <XMarkIcon class="size-4 block text-red-800 group-hover:text-red-800" />
      </button>
    </div>
    <div
      v-if="$page.props.errors"
      class="mb-8 flex max-w-3xl items-center justify-between rounded bg-red-400"
    >
      <div class="flex items-center">
        <XCircleIcon class="size-5 text-white ml-4 mr-2 flex-shrink-0" />
        <div class="py-4 text-sm font-medium text-white">
          <span v-if="Object.keys($page.props.errors).length === 1">There is one form error.</span>
          <span v-else>There are {{ Object.keys($page.props.errors).length }} form
            errors.</span>
        </div>
      </div>
      <button type="button" class="group mr-2 p-2" @click="$page.props.errors = null">
        <XMarkIcon class="size-4 block text-red-600 group-hover:text-red-700" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { onMounted, watch } from 'vue';
import { usePage } from '@inertiajs/vue3';
import { CheckIcon, XMarkIcon, XCircleIcon } from '@heroicons/vue/24/outline';

let timer;
const clearFlash = () => {
  usePage().props.flash = {
    "success": null,
    "alert": null
  }
}

watch(() => usePage().props.flash, (val) => {
  if (!(!val.success && !val.alert))  {
    clearTimeout(timer);
    timer = setTimeout(() => {
      clearFlash()
    }, 3000);
  }
}, { immediate: true, deep: true });

onMounted(() => {
  timer = setTimeout(() => {
    clearFlash()
  }, 3000);
})
</script>
